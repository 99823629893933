import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { getConfig, numberFormat, checkToken } from '../../../../redux/config/config';
import { authHeader, history } from '../../../../redux/logic';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  CircularProgress,
  CardHeader
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DuesTable = props => {
  const { className, loading, users,  investments, ...rest} = props;
  const {savings} = props;
  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

const [open, setOpen] = useState(false);
const [isLoading, setIsLoading] = useState(true);
const [details, setDetails] = useState([]);

const handleOpen = (id) => {
  setIsLoading(false)
  setOpen(true);
  const data = users.find(users => users.id === id);
  setDetails(data)
//   let user = JSON.parse(localStorage.getItem('admin'));
//   const requestOptions = {
//     method: 'GET',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//   };
//   fetch(getConfig('getAllDues') + id + `?token=`+user.token, requestOptions)
//   .then(async response => {
//   const data = await response.json();
//   if (!response.ok) {
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//   }
//   console.log(data)
//   setDetails(data)
//   setIsLoading(false)
//   // setName(data[1].package_name && data[2].commission)
//   })
//   .catch(error => {
//   if (error === "Unauthorized") {
//       history.push('/login');
//       }
//       setIsLoading(false)
//   console.error('There was an error!', error);
// });
};

const handleDelete = (id) => {
swal({
  title: "Are you sure?",
  text: "Once deleted, you will not be able to recover this file!",
  icon: "warning",
  buttons: true,
  dangerMode: true,
})
.then((willDelete) => {
  if (willDelete) {
    props.deleteTargetCommission(id);
    swal("Loading...",{   
      buttons:false
    });
  }
});
}
  
const handleSubmitEdit = (event) => {
  event.preventDefault();
  if (details.due_name && details.due_fees) { 
    console.log(details)   
    props.EditDues(details);
   }
}

const handleClose = () => {
  setOpen(false);
};

const handleChangeEdit = (e) => {
e.persist();
  setDetails(details=>({ ...details, [e.target.name]:e.target.value}))
}

 return (
      <Card className={clsx(classes.root, className)} >
        {/* Modal */}
        < Dialog
          open={open}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle  id="alert-dialog-slide-title">Update Due </DialogTitle>  
          <Divider />     
          <DialogContent>
            <CardContent className={classes.content}>
              {isLoading? <Typography>Loading...</Typography>:
              <form  noValidate autoComplete="off" onSubmit={handleSubmitEdit}>
                  <Grid style={{ marginTop: '1rem' }}>
                    <Typography>Due Name</Typography>
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={handleChangeEdit}
                      name="due_name"   
                      value={details.due_name} 
                      readOnly
                      margin="dense"
                      variant="outlined"
                      >
                    </TextField>                    
                  </Grid>
                  <Grid style={{ marginTop: '1rem' }}>
                    <Typography>Due Fee</Typography>
                  </Grid>
                  <Grid>
                    <OutlinedInput
                        fullWidth
                        placeholder="Due Fee"
                        name="due_fees"
                        type="number"
                        margin="dense"
                        value={details.due_fees} 
                        onChange={handleChangeEdit}
                        variant="outlined"
                    />
                </Grid>                  
                <Grid item md={10} xs={10} style={{ marginTop: '1rem' }}>
                  {savings && <CircularProgress />}
                  <Button color="primary" onClick={handleSubmitEdit} variant="contained" >
                    Update 
                  </Button>
                  <Button onClick={handleClose} variant="contained" 
                  style={{marginLeft:10, color:'white', backgroundColor:'red'}}>
                    Cancel
                  </Button>
                </Grid>
              </form>}
            </CardContent>
          </DialogContent>
        </Dialog>
        {/* Modal */}
        <CardHeader subheader="Dues Table" title="Dues"/>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Enter By</TableCell>
                    <TableCell>Due Name</TableCell>
                    <TableCell>Due Fee</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading?
                <TableRow><TableCell><CircularProgress /></TableCell></TableRow>: 
                users.length !== 0 ?
                users.slice(page * rowsPerPage, page* rowsPerPage + rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell>{user.created_by}</TableCell>
                    <TableCell>{user.due_name}</TableCell>
                    <TableCell>{user.due_fees}</TableCell>
                    <TableCell>
                      {moment(user.created_at).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Grid style={{display:'flex'}}>
                          <Button color="primary" variant="contained" 
                          onClick={()=> handleOpen(user.id)}
                          > Edit</Button>
                            {/* <Button  color="primary" style={{marginLeft:10, background:'red', color:'#fff'}} variant="contained" 
                            onClick={()=> handleDelete(user.id)}
                            > Delete</Button> */}
                          </Grid>
                      </TableCell>
                  </TableRow>
                )):
                <TableRow>
                <TableCell colSpan={5} style={{textAlign:"center"}}>
                    No Record Found
                  </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={users.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  EditDues: adminActions.EditDues,
//   deleteTargetCommission: adminActions.deleteTargetCommission,
};
// export default DuesTable;

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(DuesTable))
);