import React, { Component } from 'react'
import { SearchInput } from 'components'
import { adminActions } from 'redux/action';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
    DialogActions,
    DialogContent,
    CardContent,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    Button,
    TextField,
    CircularProgress,
  } from '@material-ui/core';
import { getConfig } from 'redux/config/config';
import { authHeader } from 'redux/logic';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DuesTable from './UsersTable/DuesTable';

class Dues extends Component {

    constructor(props){
        super(props)
        this.state ={  
          data:{
            due_name : "",
            due_fees : ""
          },
          open:false,
          users: [],
          all: [],
          search: "",
          loading: true,
        }  
    
        this.fetchUsers = this.fetchUsers.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }  
    
componentDidMount(){
    this.fetchUsers();
}
    
handleChange(event) {
    const { name, value } = event.target;
    const { data } = this.state;

    this.setState({
        data: {
            ...data,
            [name]: value
        }
    });
}
    
handleClose= () =>{
    this.setState({open:false})
}

handleSubmit(event) {
    event.preventDefault();
    const { data } = this.state;
    console.log(data);
    if ( data.due_name && data.due_fees) {
        this.props.addDues(data);
    }
} 

fetchUsers = () =>{
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getAllDues'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
        this.setState({users: [], all:[], loading:false});
    }else{
        this.setState({users: data.data, all:data, loading:false});
    }
    })
    .catch(error => {
    if (error === "Unauthorized") {
        this.props.logout()
    }
  });
}

handleOpen= () =>{
    this.setState({open:true})
}
      
searchChange(event) {
    const { name, value } = event.target;
    const { search, users, all } = this.state;
    this.setState({ search: value, users: value === "" ? all : all.filter((q)=>
        q.package_name.toLowerCase().indexOf(value.toLowerCase())  !== -1
    )});
}
      
    render() {
        const {users, open, search, data, loading} = this.state
        return (
            <div style={{padding: 20}}>
                <div style={{height: '42px',alignItems: 'center', marginTop: 10}}>
                    <SearchInput
                        value={search}
                        onChange={this.searchChange}
                        style={{marginRight: 5, width:300, float:'left'}}
                        placeholder="Search Package Name"
                    />
                    <div style={{float:'right'}}>
                        <div className="row">
                            <span className="spacer" />
                            <Button
                            color="primary"
                            variant="contained"
                            onClick={()=>this.handleOpen()}>
                            Add Dues
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <DuesTable users={users} loading={loading}/>
                </div>

                {/* Modal */}          
                < Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth = {'xs'}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle  id="alert-dialog-slide-title">Add Dues</DialogTitle>  
                    <Divider />     
                    <DialogContent>
                    <CardContent className="content">
                    <form  noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid >
                        <Grid>
                            <label>Due Name</label>
                            <OutlinedInput
                                fullWidth
                                placeholder="Due Name"
                                name="due_name"
                                type="text"
                                margin="dense"
                                value={data.due_name} 
                                onChange={this.handleChange}
                                variant="outlined"
                            />
                            </Grid><br/>
                            <Grid>
                            <label>Due Fee</label>
                            <OutlinedInput
                                fullWidth
                                placeholder="Due Fee"
                                name="due_fees"
                                type="number"
                                margin="dense"
                                value={data.due_fees} 
                                onChange={this.handleChange}
                                variant="outlined"
                            />
                        </Grid>                   
                    </Grid>
                    </form>
                    </CardContent>             
                    {/* </DialogContentText> */}
                    <Divider /> 
                    <DialogActions>
                    <Grid item md={10} xs={10}>
                    {this.props.savings && <CircularProgress />}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{marginLeft:8}}
                        onClick={this.handleSubmit}>
                        Add Due
                    </Button>
                    </Grid> 
                    <Button onClick={this.handleClose} 
                        variant="contained"
                        style={{color:'white', marginRight:8, backgroundColor:'red'}}>
                        Cancel
                    </Button>
                    </DialogActions>
                    </DialogContent>
                </Dialog>
                {/* Modal */}
            </div>
        )
    }
}
function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  
  const actionCreators = {
    logout: adminActions.logout,
    addDues: adminActions.addDues,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,actionCreators)(Dues))
  );
