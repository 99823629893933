import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { getConfig, checkToken, numberFormat } from 'redux/config/config'
import { authHeader, history } from 'redux/logic';

import {
  Card,
  CardContent,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Menu,
  DialogActions,
  Slide,
  CircularProgress
} from '@material-ui/core';
import Paginate from 'views/pages/components/Users/UsersTable/paginate';
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const CategoryTable = props => {
  const { className, loading, users,  investments, ...rest} = props;
  const {savings} = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({category_name:""});
  
const handleOpen = (id) => {
    setOpen(true);
};


const handleSubmitEdit = (event) => {
  event.preventDefault();
  if (details.category_name) {
    console.log(details)
    props.addProductCategory(details);
    }
}

const handleDelete = (id) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this file!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      props.deleteProductCategory(id);
      swal("Loading...",{   
        buttons:false
      });
    }
  });
}

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow><TableCell><CircularProgress /></TableCell></TableRow>:
                users.length !== 0 ?
                users.map(user => (
                 <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.category_name}</TableCell>
                    <TableCell>
                        <Button variant="contained" color="secondary"
                          onClick={()=>handleDelete(user.id)}
                        >Delete</Button>
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                  <TableCell style={{textAlign:"center"}}>
                      No Record Found
                  </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  addProductCategory: adminActions.addProductCategory,
  deleteProductCategory: adminActions.deleteProductCategory,
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(CategoryTable))
);