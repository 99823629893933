import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react'
import { Component } from 'react';
import PropTypes from 'prop-types';
import BanksTable from './UsersTable/BanksTable';
import Commission from './Commission';
import Dues from './Dues';
import Setting from './Account';
import { Password } from '.';
import Payment from './Payment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
export default class CustomTabs extends Component {
    constructor(props){
        super(props)
        this.state ={
          value:this.props.value
        }
        this.handleChange = this.handleChange.bind(this);
      }
    
    handleChange = (event, value) => {
      this.setState({value:value})
    };
    render(){
        const {theme} = this.props
        const {value} = this.state
        return (
            <div style={{flexGrow: 1}}>
                <AppBar position="static" color="default" >
                  <Tabs value={value} indicatorColor="primary"
                      textColor="primary" 
                      onChange={this.handleChange} aria-label="simple tabs example">
                      <Tab label="Commissions" {...a11yProps(0)} />
                      <Tab label="Dues" {...a11yProps(1)} />
                      <Tab label="Banks" {...a11yProps(2)} />
                      <Tab label="Payment Option" {...a11yProps(3)} />
                      <Tab label="Accounting" {...a11yProps(4)} />
                      <Tab label="Password" {...a11yProps(5)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <Commission />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Dues />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <BanksTable />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Payment />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Setting />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <Password />
                </TabPanel>
            </div>
        )
    }
}