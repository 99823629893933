import React, { Component } from 'react'
import SimpleTabs from '../components/Tabs/TargetTabsPanel'

class TargetTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}

  export default TargetTab;