import React,{Component} from 'react';
import CustomTabs from './CustomTabs';

class CustomAccountTab extends Component{

render(){
  return (
    <CustomTabs value={3}/>
  );
}
}


export default CustomAccountTab