import React from 'react';
import clsx from 'clsx';
import { withRouter } from "react-router-dom";
import { adminActions } from "redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { numberFormat } from 'redux/config/config'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Paginate from 'views/pages/components/Users/UsersTable/paginate';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const ProductTable = (props) => {
  const { className, loading, data, table, banks, investments, ...rest} = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Paystack ID</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Repayment Amount</TableCell>
                  <TableCell colSpan={2}>Transaction Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow className={classes.tableRow} ><TableCell colSpan={5}><CircularProgress /></TableCell></TableRow>:
                data.length !== 0 ?
                data.map((user, i) => (
                  <TableRow className={classes.tableRow} hover key={i}>
                    <TableCell>{i+1}</TableCell>
                    <TableCell>{user.paystack_id}</TableCell>
                    <TableCell>{user.payment_method}</TableCell>
                    <TableCell>{user.repayment_amount === 0 || user.repayment_amount === null ? 0: numberFormat(user.repayment_amount)}</TableCell> 
                    <TableCell>{user.trans_date}</TableCell> 
                  </TableRow>
                )):
                <TableRow>
					<TableCell colSpan={5} style={{textAlign:"center"}}>
						No Record Found
					</TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  cancelBusinessRequest: adminActions.cancelBusinessRequest,
  approveBusinessRequest: adminActions.approveBusinessRequest,
  addBusinessPrice: adminActions.addBusinessPrice,
  disburseBusinessLoan: adminActions.disburseBusinessLoan
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(ProductTable))
);