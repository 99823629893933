import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import moment from 'moment';
import swal from 'sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {Link} from 'react-router-dom';

import { getInitials } from 'helpers';
import Paginate from './paginate';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }    
}));

const UsersTable = props => {
  const { className, loading, users, ...rest } = props;
  const classes = useStyles();

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete this User",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        props.deleteUser(id);
      swal("Loading...",{   
          buttons:false
        });
      }
    });
    }

  return (
    <Card  className={clsx(classes.root, className)} >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Reg date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Online Banking</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {loading?
              <TableRow><TableCell><CircularProgress /></TableCell></TableRow>:
              users && users.length !== 0 ?
               users.map(user => (
                 <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={user.profile_pic}
                        >
                          {getInitials(user.last_name)}
                        </Avatar>
                        <Typography variant="body1">{user.last_name + " " + user.first_name }</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone_no}</TableCell>
                    <TableCell>{moment(user.date_entered).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{user.user_status==0?<span style={{color:'red',fontWeight:'bold'}}>Inactive</span>:
                                user.user_status==1?<span style={{color:'green',fontWeight:'bold'}}>Active</span>:
                                <span style={{color:'red',fontWeight:'bold'}}>Acct Closed</span>}
                    </TableCell>
                    <TableCell>{user.online_banking==0?<span style={{color:'red',fontWeight:'bold'}}>Offline</span>:
                                <span style={{color:'green',fontWeight:'bold'}}>Online</span>}
                    </TableCell>
                    <TableCell>
                      <Link to={`/userdetails/${user.id}`}><Button color="primary" variant="contained"> Details</Button></Link>
                    </TableCell>
                    <TableCell>
                      <Button onClick={()=> handleDelete(user.id)}  style={{color:'#fff', backgroundColor:'red'}} variant="contained"> Delete</Button>
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                  <TableCell colSpan="7" style={{textAlign:"center"}}>No Record Found</TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
    </Card>
  );
};

// UsersTable.propTypes = {
//   className: PropTypes.string,
//   users: PropTypes.array.isRequired
// };

// export default UsersTable;

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  deleteUser: adminActions.deleteUser,
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(UsersTable))
);
