import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PendingRequest from '../PendingRequest';
import PendingApproval from '../PendingApproval';
import ApprovedList from '../ApprovedList';
import CancelledList from '../CancelledList';
import CustomTab from './CustomTab';
import CustomTabPanels from './CustomTabPanels';
import OngoingList from '../OngoingList';
import CompletedList from '../CompletedList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SimpleTab(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(3);
    const [opened, setOpened] = React.useState(false);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const togglemenu = (e) => {
        setOpened(!opened);
     }
    return (
      <div className={classes.root} style={{alignItems:"center"}}>
        <div className="wrapper">
          <div className="main_content">
            <CustomTab value={value} handleChange={handleChange}/>
            <CustomTabPanels value={value} index={0}>
              <PendingRequest />
            </CustomTabPanels>
            <CustomTabPanels value={value} index={1}>
              <PendingApproval />
            </CustomTabPanels>
            <CustomTabPanels value={value} index={2}>
              <ApprovedList />
            </CustomTabPanels>
            <CustomTabPanels value={value} index={3}>
              <CancelledList />
            </CustomTabPanels>
            <CustomTabPanels value={value} index={4}>
              <OngoingList />
            </CustomTabPanels>
            <CustomTabPanels value={value} index={5}>
              <CompletedList />
            </CustomTabPanels>
          </div>
      </div>
      </div>
    );
  }
  