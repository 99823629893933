import React, { useState } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from "react-router-dom";
import { adminActions } from "redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { getConfig, numberFormat } from 'redux/config/config'
import { authHeader} from 'redux/logic';

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,Collapse, IconButton, CardHeader, Avatar, CardMedia, CardActions, DialogActions, Grid, TextField, CircularProgress
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Paginate from 'views/pages/components/Users/UsersTable/paginate';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const ProductTable = (props) => {
  const { className, loading, users, table, investments, downPayment, ...rest} = props;
  const {savings} = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState({})
  const [data, setData] = useState({
    down_payment:0,
    down_price:"",
    mark_up:0, 
    total_product:0,
    total:0,
    price:0})
  const [id, setId] = useState()
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

const handleClick = (id) => {
  console.log(id)
  setIsLoading(true)
  fetchOrderDetails(id)
  setOpen(true)
};

const fetchOrderDetails = (id)=>{
  let token = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig('allOrderProduct')+id+"?token="+token.token, requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  if(data.success === false){
	setOrder({});
  }else{
	console.log(data)
	setOrder(data[0]);
  }
  setIsLoading(false)
  })
  .catch(error => {

  if (error === "Unauthorized") {
      props.logout();
    }
  });
}

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseShow = () => {
    setShow(false);
  };
  const setPrice = (id) => {
    setId(id)
    setShow(true);
  };

  const handleChange = (event) =>{
    const { name, value } = event.target;
    event.persist();
    if(name === "price"){
      const amt = (downPayment / 100) * value
	  const amt1 = value - (value - amt)
      setData(data=>({ ...data, [name]:value, total_product: value - amt, down_payment:downPayment, mark_up:0, total:0, down_price:amt1}))
    }else if(name === "mark_up"){
      const amt = (Number(value) / 100) * Number(data.total_product)
      setData(data=>({ ...data, [name]:value, total:amt + Number(data.total_product)}))
    }else{
      setData(data=>({ ...data, [name]:value}))
    }
  }

  const handleSubmit = () =>{
    swal({
      title: "Are you sure?",
      text: "Click ok to continue",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        props.addPrice(data, id);
        swal("Loading...", {buttons: false})
      }
    })
  }

  const confirm=(task, id)=>{
    console.log(id)
    swal({
        title: "Are you sure?",
        text: "Once you " + task + ", you will not be able to reverse your action!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
            switch(task){
                case "approve":
                    props.approveOrder(id);
                    swal("Loading...", {buttons: false})
                    break;
                case "decline":
                    props.declineOrder(id)
                    swal("Loading...", {buttons: false})
                    break
                case "deliver":
                    props.deliverOrder(id);
                    swal("Loading...", {buttons: false})
                  break
                default:
                    return null
            }
        } else {
          console.log("close")
        }
      });
  }

  return (
    <Card className={clsx(classes.root, className)}>
      {table =="order" ?
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Id</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Order No</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead> 
              <TableBody>
                {loading?
                <TableRow><TableCell><CircularProgress /></TableCell></TableRow>:
                users.length !== 0 ?
                users.map((user, index) => (
                 <TableRow
                    className={classes.tableRow}
                    hover
                    key={index}>
                    <TableCell>{user.order_id}</TableCell>
                    <TableCell>{user.last_name + " " + user.first_name}</TableCell>
                    <TableCell>{user.order_no}</TableCell>
                    <TableCell>{numberFormat(user.total)}</TableCell> 
                    <TableCell>{user.order_status==1?"Pending":
                                user.order_status==2?"Paid":
                                user.order_status==3?"Approved":
                                user.order_status==4?"Declined":
                                user.order_status==5?"Delivered":"Closed"}
                    </TableCell>   
                    <TableCell>
                        {(user.order_status==1 || user.order_status==4 )&& 
                        <Button size="small" variant="contained" style={{background:"green", color:"white"}}
                          onClick={()=>confirm("approve", user.order_id)}
                        >Approve</Button>}
                        {user.order_status==1 &&
                        <Button size="small" variant="contained" style={{background:"red", color:"white"}}
                          onClick={()=>confirm("decline", user.order_id)}
                        >Decline</Button>}
                        {(user.order_status==2 || user.order_status==3) &&
                        <Button size="small" variant="contained" style={{background:"green", color:"white"}}
                          onClick={()=>confirm("deliver", user.order_id)}
                        >Deliver</Button>}
                        <Button size="small" variant="contained" 
                          color="primary" onClick={()=>handleClick(user.order_id)}
                        >Details</Button>
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                <TableCell colSpan="6" style={{textAlign:"center"}}>
                    No Record Found
                </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>:
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Product Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow><TableCell><CircularProgress /></TableCell></TableRow>:
                users.length !== 0 ?
                users.map((user, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={user.image}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{user.product_name}</TableCell>
                    <TableCell>{user.last_name + " " + user.first_name}</TableCell>
                    <TableCell>{numberFormat(user.price)}</TableCell> 
                    <TableCell>
                        <Button size="small" variant="contained" style={{background:"green", color:"white"}}
                          onClick={()=>setPrice(user.id)}
                        >Add Price</Button>
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                <TableCell colSpan="6" style={{textAlign:"center"}}>
                    No Record Found
                </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>}
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
      
      {/* Modal Add New start*/}
      <Dialog
          open={open}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle  id="alert-dialog-slide-title">Orders Details</DialogTitle>  
          <Divider />
          <DialogContent>
            {isLoading?
            <Typography variant="h6">Loading...</Typography>:
              <Card className={classes.root}>
            <CardHeader
              title={order.product_name}
              subheader={"Price " +order.price}
            />
            <CardMedia
              className={classes.media}
              image={order.image}
              title={order.product_name}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p"></Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>{order.product_name}</Typography>
                <Typography paragraph> {order.short_description} </Typography>
              </CardContent>
            </Collapse>
          </Card>}
        </DialogContent>
      </Dialog>
      {/* Modal Add New end */}
      
      {/* Modal Add Price start*/}
      <Dialog
          open={show}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleCloseShow}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle  id="alert-dialog-slide-title">Add Price</DialogTitle>  
          <Divider />
        <DialogContent>
            {isLoading?
            <Typography variant="h6">Loading...</Typography>:
            <>
            <CardContent className="content">
            <form noValidate autoComplete="off">
              <TextField
                fullWidth
                label="Price"
                placeholder="Price"
                margin="dense"
                name="price"
                onChange={handleChange}
                value={data.price}
                variant="outlined"
              />
              <TextField
                fullWidth
                placeholder="Down Payment"
                margin="dense"
                name="down_payment"
                validators={["required"]}
                value={downPayment + "%"}
                variant="outlined"
                type="text"
                label="Down payment"
                />
				<TextField
				  fullWidth
				  placeholder="Down payment Price"
				  margin="dense"
				  value={data.down_price}
				  variant="outlined"
				  type="text"
				  label="Down payment Price"
				/>
                <TextField
                  fullWidth
                  placeholder="Balance"
                  margin="dense"
                  name="total_product"
                  validators={["required"]}
                  value={data.total_product}
                  type="number"
                  variant="outlined"
                  label="Balance"
                />
                <TextField
                  fullWidth
                  placeholder="Mark Up"
                  margin="dense"
                  name="mark_up"
                  validators={["required"]}
                  value={data.mark_up}
                  type="number"
                  variant="outlined"
                  label="Please enter Mark Up in %"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  placeholder="Total Repayable Amount"
                  margin="dense"
                  name="total"
                  validators={["required"]}
                  value={data.total}
                  type="number"
                  variant="outlined"
                  label="Total Repayable Amount"
                />
            </form>
          </CardContent>
          <Divider /> 
          <DialogActions>
              <Grid item md={10} xs={10}>
                  {props.savings && <CircularProgress />}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{marginLeft:8}}
                    onClick={handleSubmit}>
                    Submit
                  </Button>
              </Grid> 
                <Button onClick={handleCloseShow} 
                        variant="contained"
                        style={{color:'white', marginRight:8, backgroundColor:'red'}}>
                  Cancel
                </Button>
          </DialogActions>
            </>}
        </DialogContent>
      </Dialog>
      {/* Modal Add Price end */}
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  approveOrder: adminActions.approveOrder,
  declineOrder: adminActions.declineOrder,
  deliverOrder: adminActions.deliverOrder,
  addPrice: adminActions.addPrice,
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(ProductTable))
);