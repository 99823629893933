import React, { Component } from 'react'
import SimpleTabs from './components/ProductTabPanel';

class ProductTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}
  
export default ProductTab
  