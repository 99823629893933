import React, {Component } from 'react';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl} from '../../../redux/config/config'
import { authHeader } from '../../../redux/logic';
import { Grid, Button, TextField, Card, Typography, MenuItem, CircularProgress} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

class LoanApplication extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    this.state ={
     
      data:[
        {
          user_id:"",
          guaranteed_amount:""
      },],
      loan:{
        user_id:"",
        loan_amount : "",
        repayment_amount : "",
        frequency : "",
        start_date : "",
        end_date : "",
        payment_method : "Wallet",
        loan_group : "",
        group_id:0,
      },
      banks:[],
      users: [],
      groups:[],
      packages_name:[],
      all: [],
      search: "",
      loading: true,
      open:false,
      search:{term:""},
      total:0,
      group:false
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchBanks = this.fetchBanks.bind(this);

  }

fetchBanks = () =>{
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getAllBanksDropdown'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({banks: data});
    })
    .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
    });
}

fetchUsers = (search) =>{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(search),
    };
    fetch(getConfig('getAllUsersAutoComplete'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({users: data});
})
.catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
       }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
}

fetchUsersGroup = (id) =>{
  let user = JSON.parse(localStorage.getItem("admin"));
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getUsersLoanGroup')+id+"?token="+user.token, requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success===false){
      this.setState({groups:[]});
    }else{
      this.setState({groups:data});
    }
    
    console.log(data)
  })
  .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
  });
}

handleChange(event, id) {
    const { name, value } = event.target;
    console.log(id)
    const { data} = this.state;
    const elementsIndex = data.findIndex((element, index) => index === id )
    let newArray = [...data]
    newArray[elementsIndex] = {...newArray[elementsIndex], [name]: value }
    this.setState({data: newArray});
    console.log(newArray)
}

handleSubmit(event) {
  event.preventDefault();
  const {data, loan} = this.state
    let dat = {first:[loan], data};
    console.log(dat)
    this.props.createUserLoan(dat)
}

handleIncrement = () =>{
  const {data} = this.state
  this.setState({
    data:[...data, {
        user_id:"",
        guaranteed_amount:""
    },]
  })
}

handleRemove = (id) =>{
  const {data} = this.state
  data.splice(id, 1)
  this.setState({
    data:data
  })
}

handleDataChange = (event) =>{
  event.preventDefault();
  const {loan, groups} = this.state
  const {name, value} = event.target
  if(name === "group"){
    if(value === "Members")
    this.setState({group:true, loan:{...loan, group_id:0, loan_group:""}})
    else
    this.setState({group:false})
  }else if(name === "loan_group"){
    let index = groups.find(element=> element.group_name === value)
    this.setState({loan:{...loan, group_id:index.id, [name]:value}})
  }else{
    this.setState({loan:{...loan, [name]:value}})
  }
  
}

handleChangeUser = (event, values, id) =>{
  const {name, value } = event.target;
    const { data, users, loan } = this.state;
  this.fetchUsers({term: value});
  users.forEach(user => {
    if(values === user.id + user.first_name + " " + user.last_name){
      this.fetchUsersGroup(user.id);
      this.setState({loan:{...loan, user_id:user.id}})
    }
  });
  
}

handleChangeUsers = (event, values, id) =>{
  const {name, value } = event.target;
    const { data, users } = this.state;
    let newArray = [...data];
  this.fetchUsers({term: value});
  users.forEach(user => {
    if(values === user.id + user.first_name + " " + user.last_name){
      const elementsIndex = data.findIndex((element, index) => index === id )
      newArray[elementsIndex] = {...newArray[elementsIndex], user_id: user.id}
    }
  });
  console.log(newArray)
  this.setState({data: newArray});
}

render(){
  const style = {
    cards:{
      padding:10,
      margin:10
    },
    title: {
      marginTop: 5,
      marginBottom:25,
      textAlign:"center",
      color: "Green"
    }
  }
  const {theme} = this.props
  const {users, groups, packages_name, group, banks, loan, bank, loading, search, handleSubmit, handleChange, index, data, open, searchChange} = this.state
  
    return (
      <div style={{padding: theme.spacing(3)}}>
      <ValidatorForm onSubmit={this.handleSubmit} >
        <Card elevation={5} style={style.cards}>
            <Typography
              style={style.title}
              variant="h2">
                 Loan Application
            </Typography>
          <Grid container>
            <Grid item lg={2} md={2} sm={4} xs={4}>
                <TextField
                  fullWidth
                  label="Guarantor Type"
                  margin="normal"
                  name="group"
                  onChange={this.handleDataChange}
                  select
                  helperText="Select Guarantor Type"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}>
                    {/* <MenuItem value="Groups">Groups</MenuItem> */}
                    <MenuItem value="Members">Members</MenuItem>
                </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item lg={3} md={3} sm={4} xs={4}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(event, value) => this.handleChangeUser(event, value, index)}
                options={users.map((option) =>option.id + option.first_name + " " + option.last_name )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event, value) => this.handleChangeUser(event, value, index)}
                    label="Search users"
                    helperText="Select user"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter amount value"
                  label=" Loan Amount"
                  name="loan_amount"
                  onChange={this.handleDataChange}
                  value={loan.loan_amount}
                  type="number"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter Repayment amount"
                  label=" Repayment Amount"
                  name="repayment_amount"
                  onChange={this.handleDataChange}
                  value={loan.repayment_amount}
                  type="number"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter Start date"
                  name="start_date"
                  onChange={this.handleDataChange}
                  value={loan.payment_date}
                  type="date"
                  variant="outlined"
                />
            </Grid>
          {group == "Group" &&<>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                  <TextValidator
                    fullWidth
                    margin="normal"
                    helperText="Enter End date"
                    name="end_date"
                    onChange={this.handleDataChange}
                    value={loan.end_date}
                    type="date"
                    variant="outlined"
                  />
            </Grid>
          </>}
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextField
                  fullWidth
                  label="Frequency"
                  margin="normal"
                  name="frequency"
                  onChange={this.handleDataChange}
                  select
                  helperText="Select Frequency"
                  variant="outlined">
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                </TextField>
            </Grid>
            {/* {!group &&<> */}
            {group == "Group" &&<>
            <Grid item lg={3} md={3} sm={4} xs={4}>
              <TextValidator
                label="Loan Group"
                fullWidth
                name="loan_group"
                margin="normal"
                variant="outlined"
                onChange={this.handleDataChange}
                helperText="Select Frequency"
                select>
                  <MenuItem value={loan.loan_group}>{loan.loan_group}</MenuItem>
                  {groups.map((option, index) => (
                    <MenuItem key={index} value={option.group_name}>
                      {option.group_name}
                    </MenuItem>
                  ))}
                </TextValidator>
          </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4} className="pt-10">
              {this.props.savings && <CircularProgress /> }
              <Button variant="contained" size="small" type="submit" color="primary">Submit</Button>
            </Grid>
            </>}
          </Grid>
          {group &&<>
          {data.map((dat, index) =>(
          <Grid container spacing={2} >
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(event, value) => this.handleChangeUsers(event, value, index)}
                options={users.map((option) =>option.id + option.first_name + " " + option.last_name )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event, value) => this.handleChangeUsers(event, value, index)}
                    label="Search Guarantor"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter amount value"
                  label="Guarantee Amount"
                  name="guaranteed_amount"
                  onChange={(e)=>this.handleChange(e, index)}
                  value={dat.guaranteed_amount}
                  type="number"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                />
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              {index !== 0 &&<Button margin="normal" type="button" variant="contained" color="primary" onClick={()=>this.handleRemove(index)} >Remove</Button>}
            </Grid>
          </Grid>))}
          <Grid container direction="row" justify="space-between" alignItems="center">
              <Button variant="contained" type="button" color="secondary" onClick={this.handleIncrement} >Add New</Button>
              {this.props.savings && <CircularProgress /> }
              <Button variant="contained" type="submit" color="primary">Submit</Button>
          </Grid></>}
        </Card>
      </ValidatorForm>
      </div>
  
    );
  };
}
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
// export default withStyles({}, { withTheme: true })(Dashboard1);
const actionCreators = {
  createUserLoan: adminActions.createUserLoan,
  logout: adminActions.logout,
  transactions: adminActions.transactions,
  
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(LoanApplication))
);