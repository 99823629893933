import React, { useState } from 'react';
import clsx from 'clsx';
import { Link, withRouter } from "react-router-dom";
import { adminActions } from "redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { numberFormat } from 'redux/config/config'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle, ListItem, ListItemText, List, DialogActions, Grid, TextField, CircularProgress,IconButton, Tooltip, CardActions, TablePagination
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import VisibilityIcon from '@material-ui/icons/Visibility'
import Paginate from 'views/pages/components/Users/UsersTable/paginate';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const ProductTable = (props) => {
  const { className, loading, data, table, banks, investments, ...rest} = props;
  const {savings} = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [bankData, setBankData] = useState({});
  const [details, setDetails] = useState({interest:0, price:0,total_amount:0})

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setBankData({})
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeDisburse = (e) =>{
    console.log(singleData.id)
    setBankData({bank_id:e.target.value, id:singleData.id })
  }

  const handleDisburse = (id) =>{
    const dat =  data.find((u,i)=>(u.id === id))
    console.log(dat)
    setSingleData(dat);
    setOpen(true);
  }

  const handleSubmitDisburse = () =>{
    swal({
      title: "Are you sure you want to disburse?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        props.disburseBusinessLoan(bankData)
        swal("Loading...",{   
          buttons:false
        });
      }
    });
  }

  const handleCloseShow = () => {
    setShow(false);
  };
  const setPrice = (id) => {
    let elementsIndex = data.find(element => element.id === id )
    console.log(elementsIndex.id)
    console.log(id)
    setDetails(details=>({ ...details, id, price: elementsIndex.requested_amount}))
    setShow(true);
  };

  const handleChange= e=>{
    e.persist();
    console.log(details);
    setDetails(details=>({ ...details, interest:  e.target.value, total_amount:details.price + Number(e.target.value)}))
  }

  const handleSubmit = () =>{
    swal({
      title: "Are you sure?",
      text: "Click ok to continue",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        props.addBusinessPrice(details);
        swal("Loading...", {buttons: false})
      }
    })
  }

  const confirm=(task, id)=>{
    swal({
        title: `Are you sure you want to ${task},`,
        text: 'click ok to continue',
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
            switch(task){
                case "approve":
                    props.approveBusinessRequest(id);
                    swal("Loading...", {buttons: false})
                    break;
                case "decline":
                  props.cancelBusinessRequest(id);
                  swal("Loading...", {buttons: false})
                  break;
                default:
                    return null
            }
        } else {
          console.log("close")
        }
      });
  }

  return (
    <Card className={clsx(classes.root, className)}>
        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Requested Amount</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Repayment Amount</TableCell>
                  <TableCell>File</TableCell>
                  {table !== "completed" && table !== "ongoing" &&<TableCell colSpan={2}>Start Date</TableCell>}
                  {table !== "approved" && table !== "cancelled" &&<TableCell colSpan={2}>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow className={classes.tableRow} ><TableCell colSpan={8}><CircularProgress /></TableCell></TableRow>:
                data.length !== 0 ?
                data.map((user, i) => (
                 <TableRow className={classes.tableRow} hover key={i}>
                    <TableCell>{i+1}</TableCell>
                    <TableCell>{user.last_name + " " + user.first_name}</TableCell>
                    <TableCell>{user.frequency}</TableCell> 
                    <TableCell>{user.requested_amount === 0 || user.requested_amount === null ? 0 : numberFormat(user.requested_amount)}</TableCell> 
                    <TableCell>{user.total_amount === 0  || user.total_amount === null ? 0 : numberFormat(user.total_amount)}</TableCell> 
                    <TableCell>{user.repayment_amount === 0 || user.repayment_amount === null ? 0: numberFormat(user.repayment_amount)}</TableCell> 
                    <TableCell>
                      <a href={user.image} target="_blank">
                      <Tooltip title="Download file">
                        <IconButton>
                          <DownloadIcon />
                        </IconButton>
                        </Tooltip>
                      </a>
                    </TableCell> 
                    <TableCell>{user.start_date}</TableCell> 
                    <TableCell colSpan={2}>
                        {(user.request_status ==0 || user.request_status === 2 || user.request_status === 1) &&
                        <Tooltip title={user.request_status === 0?'Add markup':
                            user.request_status === 2?'Approve':
                            user.request_status === 1?'Disburse':''}>
                          <IconButton size="small" variant="contained" color="primary"
                            onClick={user.request_status === 0? ()=>setPrice(user.id):
                            user.request_status === 2?()=>confirm("approve", user.id):
                            user.request_status === 1?()=>handleDisburse(user.id):()=>console.log('weldone')}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>}
                        {table === "ongoing"|| table === "completed" ?
                        <Link to ={`/business-details/${table}/${user.id}`}>
                          <Tooltip title="Details">
                            <IconButton size="small" variant="contained" color="secondary">
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>:
                        table === "request"|| table === "approval"&&
                        <Tooltip title="Decline">
                          <IconButton onClick={()=>confirm("decline", user.id)} size="small" variant="contained" color="secondary">
                            <CancelPresentationIcon />
                          </IconButton>
                        </Tooltip>}
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                <TableCell colSpan={8} style={{textAlign:"center"}}>
                    No Record Found
                </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>

      {/* Modal Add New start*/}
      <Dialog
          open={show}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleCloseShow}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle  id="alert-dialog-slide-title">Add Price</DialogTitle>  
          <Divider />
        <DialogContent>
            <CardContent className="content">
            <Typography variant="h5">Loan Amount:{details.price}</Typography><br/>
            <Typography variant="h5">Mark up Amount:{details.interest}</Typography><br/>
            <Typography variant="h5">Total Amount: {Number(details.price) + Number(details.interest)}</Typography>
            <form  noValidate autoComplete="off">
              <Grid>
                <Grid>
                  <TextField
                    fullWidth
                    label="Price"
                    placeholder="Price"
                    margin="dense"
                    name="interest"
                    onChange={handleChange}
                    value={details.interest}
                    variant="outlined"
                    type='number'
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <Divider /> 
          <DialogActions>
              <Grid item md={10} xs={10}>
                  {/* {props.savings && <CircularProgress />} */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{marginLeft:8}}
                    onClick={handleSubmit}>
                    Submit
                  </Button>
              </Grid> 
                <Button onClick={handleCloseShow} 
                  variant="contained"
                  style={{color:'white', marginRight:8, backgroundColor:'red'}}>
                  Cancel
                </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Modal Add New end */}

      {/* Disburse Modal */}
      {banks && <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle  id="alert-dialog-slide-title">Business Loan Details</DialogTitle>  
        <Divider />
        <DialogContent>
          <List>
            <ListItem divider >
              <ListItemText
                primary={`Guarantors Name: ${singleData.first_name + " " + singleData.last_name}`}
              />
            </ListItem>
            <ListItem divider >
              <ListItemText
                primary={`Loan Amount: ${singleData.total_amount}`}
              />
            </ListItem>
            <ListItem divider >
              <ListItemText
                primary={`Repayment Amount: ${singleData.repayment_amount}`}
              />
            </ListItem>
            <ListItem divider >
              <ListItemText
                primary={`Payment Duration: ${singleData.repayment_duration}`}
              />
            </ListItem>
          </List>
              <TextField
                fullWidth
                select
                variant="outlined"
                value={singleData.bank_name} 
                onChange={handleChangeDisburse}
                SelectProps={{
                  native: true,
                }}
                helperText="Please select Bank Name"
                name="bank_name">
                  <option></option>
                  {banks.map(b =>(<option key={b.id} value={b.id}>{b.bank_name}</option>))}
              </TextField>
              <Button onClick={handleSubmitDisburse} color="primary" variant="contained">
                Disburse
              </Button>
        </DialogContent>
      </Dialog>}
      {/* Modal */}
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  cancelBusinessRequest: adminActions.cancelBusinessRequest,
  approveBusinessRequest: adminActions.approveBusinessRequest,
  addBusinessPrice: adminActions.addBusinessPrice,
  disburseBusinessLoan: adminActions.disburseBusinessLoan
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(ProductTable))
);