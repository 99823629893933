import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  CircularProgress,
  IconButton,
  Tooltip,
  MenuItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DeleteIcon from '@material-ui/icons/Delete';
import Paginate from '../../Users/UsersTable/paginate';
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, loading, users, savings,  investments, ...rest} = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openRole, setOpenRole] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({});

  const handleOpen = (id) => {
    setIsLoading(true)
    setDetails(users.find(user=>user.id === id))
    setOpen(true);
    
};

const handleEnable = (id, status) => {
  let msg = status === 1 ? "disable":"enable";
  swal({
    title: "Are you sure you want to "+ msg,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      if(status === 1){
        props.disableAdmin(id);
      }else{
        props.enableAdmin(id); 
      }
    swal("Loading...",{   
        buttons:false
      });
    }
  });
  }
  
const handleSubmitEdit = (event) => {
  event.preventDefault();
  if (details.name && details.email) {
    console.log(details)
    props.updateAdmin(details);
    }
}

  const handleClose = () => {
    setOpen(false);
    setOpenRole(false)
  };
//  const handleChangeEdit = (e) => {
//   e.persist();
//   setDetails(details=>({ ...details, [e.target.name]:e.target.value}))
//  }

 const handleChange = event => {
  // use spread operator
  setDetails({
    ...details,
    [event.target.name]: event.target.value,
  });
};

  return (
    <Card className={clsx(classes.root, className)}>

       {/* Modal */}
       <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Add Admin</DialogTitle>  
        <Divider />     
        <DialogContent>
          <CardContent className="content">
            <form  noValidate autoComplete="off" onSubmit={handleSubmitEdit}>
              <TextField
                fullWidth
                placeholder="Admin Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                value={details.name}
                variant="outlined"
              />
              <TextField
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                value={details.email}
                variant="outlined"
              />
              <TextField
                fullWidth
                placeholder="Select role"
                margin="dense"
                select
                name="role"
                onChange={handleChange}
                required
                value={details.role}
                variant="outlined">
                  <MenuItem value="1">{details.role}</MenuItem>
                  <MenuItem value="1">Manager</MenuItem>
                  <MenuItem value="2">Ast. Manager</MenuItem>
              </TextField>
            </form>
          </CardContent>
          <Divider /> 
          <DialogActions>
            {savings && <CircularProgress />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{marginLeft:8}}
              onClick={handleSubmitEdit}
            >
              Submit
            </Button>
            <Button onClick={handleClose} 
              variant="contained"
              style={{color:'white', marginRight:8, backgroundColor:'red'}}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Modal */}

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Admin Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Entered By</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>:
                users && users.length !== 0 ?
                users.map(user => (
                 <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.status==0?<span style={{color:'red',fontWeight:'bold'}}>Unactive</span>:
                                user.status==1?<span style={{color:'green',fontWeight:'bold'}}>Active</span>:
                                <span style={{color:'red',fontWeight:'bold'}}>Unactive</span>}                      
                    </TableCell>
                    <TableCell>
                      {moment(user.entery_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Grid style={{display:'flex'}}>
                        <Tooltip title="Edit" >
                          <IconButton color="primary" variant="contained" onClick={()=> handleOpen(user.id)} size="small"><EditIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title={user.status === 1? "Disable" : "Enable"}>
                        <IconButton variant="contained" size="small"
                          onClick={()=> handleEnable(user.id, user.status)}> 
                          {user.status === 1 ? <DeleteIcon />:<VerifiedUserIcon /> }
                        </IconButton>
                        </Tooltip>
                    </Grid>
                    </TableCell>
                  </TableRow>
                )):
                <TableRow>
                  <TableCell colSpan={5} style={{textAlign:"center"}}>
                      No Record Found
                  </TableCell>             
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  updateAdmin: adminActions.updateAdmin,
  disableAdmin: adminActions.disableAdmin,
  enableAdmin: adminActions.enableAdmin,
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(UsersTable))
);