import React, { Component } from 'react'
import SimpleTabs from './components/PendingRequestTabPanel';

class PendingRequestTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}
  
export default PendingRequestTab
  