import React, { Component } from "react";
import { adminActions } from "../../../../redux/action";
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import swal from 'sweetalert'
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Dialog
} from '@material-ui/core';


class Password extends Component {
  constructor(props){
    super(props)
    this.state ={  
      data:this.props.update? this.props.update: {
        bank_name : "",
        bank_code : "",
        account_no : "",
        account_name:""
      },
      banks:[],
      open:false,
      show:false,
      loading: true,
      isChecking:false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

componentDidMount(){
  const requestOptions = {
    method: 'GET',
    headers: { 'authorization': 'Bearer sk_test_629b392e2345d122b5941f00b27cdd91957ca848', 'Content-Type': 'application/json' },
  };
  fetch('https://api.paystack.co/bank', requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      this.setState({ loading: false})
      return Promise.reject(error);
    }
    this.setState({banks: data.data})
  })
  .catch(error => {
    if (error === "Unauthorized") {
      this.props.logout()
    }
  });
}

 handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    const { data, banks} = this.state;
      this.setState({
        data: {
          ...data,
          [name]: banks[value].name,
          account_no:"",
          account_name:""
        },
        bank_code: banks[value].code,
      }) 
}

handleSubmit(event) {
  event.preventDefault();
  const { data } = this.state;
    if ( data.bank_name && data.account_name && data.account_no && data.bank_code) {
      swal("Loading...", {
        buttons: false
      })
      if(this.props.add==false){
        this.props.addNewBank(data);
      }else{
        this.props.updateBank(data);
      }
    }
}

check = (e)=>{
  const {name, value} = e.target
  const { data, bank_code } = this.state;
  this.setState({ data:{ ...data, [name]:value }, isChecking:true })
  if(e.target.value.length === 10){
    const requestOptions = {
      method: 'GET',
      headers: { 'authorization': 'Bearer sk_test_629b392e2345d122b5941f00b27cdd91957ca848', 'Content-Type': 'application/json' },
  };
    fetch('https://api.paystack.co/bank/resolve?account_number='+value+'&bank_code='+bank_code, requestOptions)
      .then(async response => {
      const res = await response.json();
      if (!response.ok) {
          const error = (data && res.message) || response.statusText;
          return Promise.reject(error);
      }
      this.setState({
        data:{
          ...data, 
          account_no:res.data.account_number, 
          account_name:res.data.account_name, 
          bank_code: bank_code},
        isChecking:false
      })
    })
    .catch(error => {
      this.setState({isChecking:false})
      swal(
        `${"Invalid account, please check the account details and try again"}`
      );
    });
  }
}
  
render(){
  const {theme, savings, show, handleClose} = this.props
  const {data, banks, isChecking} = this.state
    return (
      <Dialog
        open={show}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Card>
          <ValidatorForm onSubmit={this.handleSubmit}>
            <CardHeader
              subheader="Add New Bank"
              title="Bank Details"
            />
            <Divider />
            <CardContent>
              <TextField
                fullWidth
                label="Select Bank Name"
                name="bank_name"
                onChange={this.handleChange}
                select
                variant="outlined"
                validators={["required"]}>
                <MenuItem >{data.bank_name}</MenuItem>
                {banks.map((bank, index)=>(
                  <MenuItem key={index} value={index}>{bank.name}</MenuItem>
                ))}</TextField>
              <TextValidator
                fullWidth
                label="Type Account Number"
                name="account_no"
                onChange={this.check}
                style={{ marginTop: '1rem' }}
                type="number"
                value={data.account_no}
                variant="outlined"
                validators={["required"]}
              />
              {isChecking && (
                <CircularProgress
                  size={24}
                />)}
              <TextValidator
                fullWidth
                label="Account Name"
                name="account_name"
                style={{ marginTop: '1rem' }}
                type="text"
                value={data.account_name}
                variant="outlined"
                validators={["required"]}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                onClick={this.handleSubmit}
              >
                Add New Bank
              </Button>
            </CardActions>
          </ValidatorForm>
        </Card>
      </Dialog>
    
    );
  };
}

function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  const actionCreators = {
    addNewBank: adminActions.addNewBank,
    updateBank: adminActions.updateBank,
    logout: adminActions.logout,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,  actionCreators)(Password))
  );