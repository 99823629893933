import React, { Component } from 'react';
import { Button, Grid, CardContent, Divider, DialogContent, DialogTitle, Dialog, CircularProgress, TextField, } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl} from '../../../redux/config/config'
import { authHeader} from '../../../redux/logic';
import { UsersTable } from '../components/Users';
import { SearchInput } from 'components';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import swal from 'sweetalert'


class UserList extends Component{
  constructor(props){
    super(props)
    setLastUrl()
    this.state={
      data:{
        first_name : "",
        last_name : "",
        middle_name : "",
        email : "",
        phone_no : "",
        password : ""
      },
      search_term:{
        search_term:"",
      },
        users: [],
        loading:true,
        all: [],
        temp_data: [],
        search: "",
        open:false,
        show:false
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);

  }

componentDidMount(){
  this.fetchUsers()
}

handleOpen = () =>{
  this.setState({show: true})
}

handleClose = () =>{
  this.setState({show: false})
}

handleSubmit =()=>{
  const {data} = this.state
  if(data.first_name && data.last_name && data.phone_no &&data.middle_name){
    this.props.addUser(data)
  }
}

fetchUsers = () =>{
  const {search_term} = this.state
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body:JSON.stringify(search_term)
    };
  fetch(getConfig('getAllUsers'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data);
    this.setState({users: data.data, all:data, temp_data:data.data, loading:false});
})
.catch(error => {
 
    if (error === "Unauthorized") {
        this.props.logout();
       }
    this.setState({loading:false });
    console.error('There was an error!', error);
});
}

handleChange =(e)=>{
  e.preventDefault()
  const {name, value} = e.target
  const {data} = this.state
  this.setState({data:{...data, [name]:value }}) 
}

searchChange(event) {
  const { value } = event.target;
  const { search_term } = this.state;
    this.setState({ search_term: { ...search_term, search_term: value }, loading:true},()=>{
      this.fetchUsers()
    });
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

// handleDelete = (id) => {
//   swal({
//     title: "Are you sure you want to delete User",
//     icon: "warning",
//     buttons: true,
//     dangerMode: true,
//   })
//   .then((willDelete) => {
//     if (willDelete) {
//       this.props.saveMultipleDues(id);
//     swal("Loading...",{   
//         buttons:false
//       });
//     }
//   });
//   }
render(){
  let {theme} = this.props
  const {users, loading, search_term, show, data, all} = this.state
  return (
    <div style={{padding: theme.spacing(3)}}>
      <div style={{height: '42px', marginTop: theme.spacing(1)}}>
        <TextField
          value={search_term.search_term}
          onChange={this.searchChange}
		  style={{width:"30%"}}
          placeholder="Search user"
		  variant="outlined"
		  margin="dense"
        />
        <div style={{float:'right'}}>
            <div className="row">
              <span className="spacer" />
              <Button color="primary" onClick={this.handleOpen} variant="contained"> Add New User</Button>
            </div>
          </div>
      </div>
      <div style={{marginTop: theme.spacing(2)}}>
        <UsersTable users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} />
      </div>
      
    {/* Modal Add New start*/}
    <Dialog
        open={show}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Add New User</DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent >
          <ValidatorForm autoComplete="off" noValidate onSubmit={this.handleSubmit}>
          <Grid>
              <TextValidator
                fullWidth
                placeholder="First Name"
                margin="dense"
                name="first_name"
                validators={[
                    "required"
                  ]}
                value={data.first_name}
                variant="outlined"
                type="text"
                helperText="Please enter user first name"
                onChange={this.handleChange}
              />
              <TextValidator
                fullWidth
                placeholder="Middle Name"
                margin="dense"
                name="middle_name"
                validators={[
                    "required"
                  ]}
                value={data.middle_name}
                variant="outlined"
                type="text"
                helperText="Please enter user middle name"
                onChange={this.handleChange}
              />
              <TextValidator
                fullWidth
                placeholder="Last Name"
                margin="dense"
                name="last_name"
                validators={[
                    "required"
                  ]}
                value={data.last_name}
                variant="outlined"
                type="text"
                helperText="Please enter user last name"
                onChange={this.handleChange}
              />
              <TextValidator
                fullWidth
                placeholder="Phone number"
                margin="dense"
                name="phone_no"
                value={data.phone_no}
                variant="outlined"
                type="number"
                helperText="Please input Phone number"
                onChange={this.handleChange}
              />
            </Grid>
            {this.props.savings && <CircularProgress />}
            <Grid>
              <Button type="submit" color="primary" variant="contained">
                Submit 
              </Button>
            </Grid>
          </ValidatorForm>
        </CardContent>
      </DialogContent>
    </Dialog>
    {/* Modal Add New end */}
    </div>
    );
  };
}

// export default UserList;
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
// export default withStyles({}, { withTheme: true })(Dashboard1);
const actionCreators = {
  addUser: adminActions.addUser,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(UserList))
);