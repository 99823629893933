import React, { Component } from 'react'
import SimpleTabs from '../components/Tabs/LoanTabsPanel'

class LoanTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}

  export default LoanTab;