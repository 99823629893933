import React, { Component } from "react";
import { adminActions } from "../../../../redux/action";
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import swal from 'sweetalert'
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
// import CommentIcon from '@material-ui/icons/Comment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  TextField,
  Typography,
  Switch
} from '@material-ui/core';
import { getConfig } from "redux/config/config";
import { authHeader } from "redux/logic";
// import { data } from "views/Dashboard/components/LatestSales/chart";
// import state from "sweetalert/typings/modules/state";


class Setting extends Component {
  constructor(props){
    super(props)
    this.state ={
      data: [],
      account:[],
      codes:[],
      setting:[],
      loading: true,
      integrate:true
    }
    this.handleToggle = this.handleToggle.bind(this);
    this.fetchSettings = this.fetchSettings.bind(this);
    this.handleIntegration = this.handleIntegration.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

componentDidMount(){
  this.fetchSettings()
}

fetchSettings = () =>{
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
    fetch(getConfig('getAllAccountingSettings'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    if(data.success === false){
      this.setState({data: []});
    }else{
      this.setState({data: data});
    }
  })
  .catch(error => {
    if (error === "Unauthorized") {
      this.props.logout()
    }
  });
  fetch(getConfig('getGLCode'), requestOptions)
  .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({codes: []});
    }else{
      this.setState({codes: data});
    }
  })
  .catch(error => {
    if (error === "Unauthorized") {
      this.props.logout()
    }
  });
}
  
handleToggle = (e, id) => {
  const {data, account} = this.state
  const {value, name} = e.target
  let newData = data;
  let newAcct = account
  const acctIndex = account.findIndex(a => a.id === id)
  const dataIndex = data.findIndex(d => d.id === id)
  if(name==="code"){
    data.forEach(element =>{
      if(element.id === id){
        if(acctIndex >= 0){
          newAcct[acctIndex] = {...newAcct[acctIndex], code:value, id:element.id}
          newData[dataIndex] = {...newData[dataIndex], code:value}
        }else{
          console.log(acctIndex)
        }
      }
    })
  }else{
    data.forEach(e =>{
      if(e.id === id){
        if(acctIndex < 0){
          newAcct.push({status:!(e.status), id:e.id})
        }else{
          newAcct.splice(acctIndex, 1)
        }
        newData[dataIndex] = {...newData[dataIndex], status:!(e.status)}
      }
    })
  }
  console.log(newAcct, newData)
  this.setState({data:newData, account:newAcct})
};

handleIntegration = (event) => {
  this.setState({integrate: event.target.checked });
};

handleSubmit(event) {
  event.preventDefault();
  const { account } = this.state;
  console.log(account)
  if ( account) {
    swal("Loading...", {
      buttons: false
    })
    this.props.accountSettings(account);
  }
}

render(){
  const {data, integrate, codes} = this.state
    return (
      <div>
        <Card >
          <CardHeader title="Accounting" subheader="Update Accounting Settings"/>
          <Divider />
          <CardContent>
          <Typography variant="h5">Switch ON Accounting Integration</Typography>
            <Switch
              checked={integrate}
              onChange={this.handleIntegration}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {integrate && <div >
              {data.map((d, index)=>(
                <div key={index}>
                  <div style={{display:"flex"}}>
                    <Checkbox
                      edge="start"
                      name="status"
                      tabIndex={-1}
                      onChange={(e)=>this.handleToggle(e, d.id)}
                      checked={d.status?true:false}
                      disableRipple
                      inputProps={{ 'aria-labelledby':`checkbox-list-label-${index}`}}
                    />
                    <TextField
                      fullWidth
                      name="code"
                      label={d.package_name}
                      onChange={(e)=>this.handleToggle(e, d.id)}
                      type="number"
                      select
                      defaultValue={d.code}
                      variant="outlined"
                    >
                      {codes.map(act =>(<option key={act.account_name} value={act.account_code}> {act.account_code} </option>))}
                    </TextField>
                  </div>
                  <Divider />
                </div>
                )
              )}
            </div>}
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              onClick={this.handleSubmit}>
              Update Settings
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  };
}

function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  const actionCreators = {
    accountSettings: adminActions.accountSettings,
    logout: adminActions.logout,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,  actionCreators)(Setting))
  );