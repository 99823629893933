import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  CardHeader,
} from '@material-ui/core';
import Paginate from 'views/pages/components/Users/UsersTable/paginate';
import { adminActions } from 'redux/action';
import { numberFormat } from 'redux/config/config';
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LoanList = props => {
  const { className, loading, users, savings,  investments, ...rest} = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [details, setDetails] = useState({});



 const handleChange = event => {
  // use spread operator
  setDetails({
    ...details,
    [event.target.name]: event.target.value,
  });
};

  return (
    <Card className={clsx(classes.root, className)}>       
        <CardHeader
        title="Loan Borrowers"
      />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Customers Name</TableCell>
                  <TableCell>Loan Amount</TableCell>
                  <TableCell>Total Paid</TableCell>
                  <TableCell>Remaining Balance</TableCell>
                  <TableCell>Entry Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading?
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>:
                users && users.length !== 0 ?
                users.map((user, id )=> {
                  const startDate = new Date(user.start_date)
                  const endDate = new Date(user.end_date)
                  const paid = user.loan_amount - user.loan_balance
                  return(
                 <TableRow
                    className={classes.tableRow}
                    hover
                    key={id}
                    selected={selectedUsers.indexOf(user.id) !== -1}>
                    <TableCell>{user.last_name + " " + user.first_name }</TableCell>
                    <TableCell>{numberFormat(user.loan_amount)}</TableCell>
                    <TableCell>{numberFormat(user.loan_balance)}</TableCell>                    
                    <TableCell>{numberFormat(paid)}</TableCell>
                    <TableCell>
                      {moment(user.start_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(user.end_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{startDate <= endDate?<span style={{color:'green',fontWeight:'bold'}}>Active</span>:
                                endDate > startDate?<span style={{color:'red',fontWeight:'bold'}}>Expired</span>:
                                ""}                      
                    </TableCell>
                  </TableRow>
                )}):
                <TableRow>
                  <TableCell colSpan={5} style={{textAlign:"center"}}>
                      No Record Found
                  </TableCell>             
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Paginate pagination={props.pagination} fetch_prev_page={props.fetch_prev_page} fetch_next_page={props.fetch_next_page} fetch_page={props.fetch_page}/>
    </Card>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
};
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(LoanList))
);