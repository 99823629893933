import React, { Component } from "react";
import { adminActions } from "../../../../redux/action";
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import swal from 'sweetalert'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';


class Password extends Component {
  constructor(props){
    super(props)
    this.state ={  
      data:{
        old_password : "",
        new_password : "",
        password_confirmation : ""
      },
      open:false,
      show:false,
      loading: true,
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
  this.setState({ data: { ...data, [name]: value}});
}

handleSubmit = event => {
    event.preventDefault();
    const { data} = this.state;
      if (data.old_password && data.new_password && data.password_confirmation) {
        if(data.new_password === data.password_confirmation){
          swal("Loading...", {
            buttons: false
          })
          this.props.adminChangePassword(data);
        //   console.log(data);
        }else{
          swal(`Password Not Match`);
        }
      }else{
        swal(`${"All fields are required"}`);
    }
}

render(){
  const {theme, savings} = this.props
  const {data} = this.state
    return (
      <div style={{marginTop:40, display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Card>
        <form>
          <CardHeader
            subheader="Update password"
            title="Password"
          />
          <Divider />
          <CardContent>
            <TextField
              fullWidth
              label="Old Password"
              name="old_password"
              onChange={this.handleChange}
              type="password"
              value={data.old_password}
              variant="outlined"
              size="small"
            />
            <TextField
              fullWidth
              label="Password"
              name="new_password"
              onChange={this.handleChange}
              style={{ marginTop: '1rem' }}
              type="password"
              value={data.new_password}
              variant="outlined"
              size="small"
            />
            <TextField
              fullWidth
              label="Confirm password"
              name="password_confirmation"
              onChange={this.handleChange}
              style={{ marginTop: '1rem' }}
              type="password"
              value={data.password_confirmation}
              variant="outlined"
              size="small"
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </CardActions>
        </form>
        </Card>
        
      </div>
    
    );
  };
}
  

// function mapState(state) {
//     const { savings } = state.savings;
//     return { savings };
//   }
  
//   const actionCreators = {
//     adminChangePassword: adminActions.adminChangePassword,
//   };

// export default ChangePassword;
function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  const actionCreators = {
    saveWallet: adminActions.saveWallet,
    logout: adminActions.logout,
    adminChangePassword: adminActions.adminChangePassword,
    adminAddHalalNews: adminActions.adminAddHalalNews,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,  actionCreators)(Password))
  );