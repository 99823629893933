import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { ValidatorForm } from "react-material-ui-form-validator";
import { AddCircle } from '@material-ui/icons';
import {
  Card,
  CardContent,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  CircularProgress,
  CardHeader,
  MenuItem,
  DialogActions,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  FormLabel
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const RolesTable = props => {
  const { className, loading, roles, permissions, investments, ...rest} = props;
  const {savings} = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [singleRole, setSingleRole] = useState({});
  const [openRole, setOpenRole] = useState(false)
  const [details, setDetails] = useState({role:""});
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (details.role) {
      props.createRole(details);
    }
  }

  const handleSubmitEdit = (event) => {
    event.preventDefault();
    if (singleRole.role) {
      props.updateRole(singleRole);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false)
  };

  const handleChange = (e, type) => {
    e.persist();
    if(type === "create"){
      setDetails({[e.target.name]:e.target.value})
    }else{
      setSingleRole(single=>( {...single, [e.target.name]:e.target.value}))
    }
    
  }
  const handleAddRole = ()=>{
    setOpenRole(true)
  }

  const handleOpen = () =>{
    setOpen(true)
  }

  const handleEdit = (id) =>{
    const f = roles.find(r => id === r.id)
    setSingleRole(f)
    setEdit(true)
  }

 return (
  <Card className={clsx(classes.root, className)} >
      {/* Modal */}
      < Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  id="alert-dialog-slide-title">Create New ROle </DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent className={classes.content}>
            <form  noValidate autoComplete="off">
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e)=>handleChange(e, "create")}
                name="role"   
                value={details.role} 
                readOnly
                margin="dense"
                variant="outlined"
              >
              </TextField>
              <Grid item md={10} xs={10} style={{ marginTop: '1rem' }}>
                {savings && <CircularProgress />}
                  <Button color="primary" onClick={handleSubmit} variant="contained" >
                    Submit
                  </Button>
              </Grid>
            </form>
          </CardContent>   
        </DialogContent>
      </Dialog>
      {/* Modal */}

      {/* Modal */}
      < Dialog
        open={edit}
        fullWidth={true}
        maxWidth = {'xs'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle  id="alert-dialog-slide-title">Update Role </DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent className={classes.content}>
            <form  noValidate autoComplete="off">
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e)=>handleChange(e, "edit")}
                name="role"   
                value={singleRole.role} 
                readOnly
                margin="dense"
                variant="outlined"
              >
              </TextField>
              <Grid item md={10} xs={10} style={{ marginTop: '1rem' }}>
                {savings && <CircularProgress />}
                  <Button color="primary" onClick={handleSubmitEdit} variant="contained" >
                    Update
                  </Button>
              </Grid>
            </form>
          </CardContent>   
        </DialogContent>
      </Dialog>
      {/* Modal */}

      {/* Modal */}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Add Admin</DialogTitle>  
        <Divider />     
        <DialogContent>
          <CardContent className="content">
            <form  noValidate autoComplete="off" onSubmit={handleSubmitEdit}>
              <TextField
                fullWidth
                placeholder="Admin Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                value={details.name}
                variant="outlined"
              />
              <TextField
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                onChange={handleChange}
                value={details.email}
                variant="outlined"
              />
              <TextField
                fullWidth
                placeholder="Select role"
                margin="dense"
                select
                name="role"
                onChange={handleChange}
                required
                value={details.role}
                variant="outlined">
                  <MenuItem value="1">{details.role}</MenuItem>
                  <MenuItem value="1">Manager</MenuItem>
                  <MenuItem value="2">Ast. Manager</MenuItem>
              </TextField>
            </form>
          </CardContent>
          <Divider /> 
          <DialogActions>
            {savings && <CircularProgress />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{marginLeft:8}}
              onClick={handleSubmitEdit}
            >
              Submit
            </Button>
            <Button onClick={handleClose} 
              variant="contained"
              style={{color:'white', marginRight:8, backgroundColor:'red'}}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Modal */}

      {/* Modal Add Role permission start*/}
      <Dialog
        open={openRole}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Add Admin Role</DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent >
          <ValidatorForm autoComplete="off" noValidate>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">All Permissions</FormLabel>
              
            </FormControl>
            {props.savings && <CircularProgress />}
            <Grid>
              <Button type="submit" color="primary" variant="contained">
                Submit 
              </Button>
            </Grid>
          </ValidatorForm>
        </CardContent>
      </DialogContent>
    </Dialog>
    {/* Modal Add New end */}

    <CardHeader subheader="Roles Table" title="Roles" 
      action={
          <Button color="primary" variant="contained" 
            onClick={handleOpen}
          > Create Role
          </Button>
        }/>
    <Divider />
    <CardContent className={classes.content}>
      <PerfectScrollbar>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Role Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {loading?
            <TableRow><TableCell><CircularProgress /></TableCell></TableRow>: 
            roles.length !== 0 ?
            roles.map((r, i) => (
              <TableRow
                className={classes.tableRow}
                hover
                key={i}
              >
                <TableCell>{i+1}</TableCell>
                <TableCell>{r.role}</TableCell>
                <TableCell>
                  <Button color="primary" variant="contained" 
                  onClick={()=> handleEdit(r.id)}
                  > Edit
                  </Button>
                  <Button color="primary" onClick={handleAddRole}><AddCircle />
                    Add Permmission
                  </Button>
                </TableCell>
              </TableRow>
            )):
            <TableRow>
              <TableCell colSpan={2} style={{textAlign:"center"}}>
                No Record Found
              </TableCell>              
            </TableRow>
            }
          </TableBody>
         </Table>
        </div>
      </PerfectScrollbar>
    </CardContent>
  </Card>
);
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  createRole: adminActions.createRole,
  updateRole: adminActions.updateRole,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(RolesTable))
);