import React, { Component } from 'react'

class AccountFrame extends Component {
    render() {
        const id = this.props.match.params.id;
        const base = "http://142.93.152.229/ace/index.php?application="
        return (
            <div style={{marginBottom:50}}>
                <iframe style={{position: "absolute", height: "100%", border: "none"}} src={base + id} width="100%" height="100%"></iframe>
            </div>
        )
    }
}

export default AccountFrame
