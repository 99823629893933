import React, { Component } from 'react'
import SimpleTabs from './components/CancelledListTabPanel';

class CancelledListTab extends Component {
    render() {
        return (
            <div>
              <SimpleTabs />
            </div>
        )
    }
}
  
export default CancelledListTab
  