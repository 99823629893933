/* eslint-disable camelcase */
import React, { useState } from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Button, Tooltip  } from '@material-ui/core'

function ExportButton({ users, title}) {
    const [exportLoading, setExportLoading] = useState(false)
    const exportToCSV = () => {
        setExportLoading(true)
        const ws = XLSX.utils.json_to_sheet(users)
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        })
        FileSaver.saveAs(data, 'Sesis-transactions.xlsx')
        setTimeout(() => {
          setExportLoading(false)
        }, 3000)
      }

  return (
    <div> 
        <Tooltip title={title}>
            <Button color="primary" variant="contained" loading={exportLoading} onClick={exportToCSV}>
                Export
            </Button >
        </Tooltip>       
    </div>
  )
}

export default ExportButton
