import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl } from '../../../redux/config/config'
import { authHeader } from '../../../redux/logic';
import { Grid, MenuItem, TextField, Tooltip} from '@material-ui/core';
import { UsersToolbar } from '../components/TranscationTable';
import TransactionTable from '../components/Table/TransactionTable';
import ExportButton from '../components/Table/TransactionTable/export';


class Transactions extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    const id = this.props.match.params.id;
    this.state ={
      data:{
        id:id,
        from_date : "",
        to_date:"",
        new_search:"",
      },
      users: [],
      trans: [],
      allTrans: [],
      all: [],
      search: "",
      loading: true,
      check: false,
      open:false
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchUsers();
    this.fetchAllTrans = this.fetchAllTrans.bind(this);
    this.fetchAllTrans();
    this.fetchTrans = this.fetchTrans.bind(this);
    this.fetchTrans();
    this.searchChange = this.searchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);

  }

fetchAllTrans = () =>{
    const {data} = this.state
    let user = JSON.parse(localStorage.getItem('admin'));
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };
    fetch(getConfig('export_all_transactions'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    // console.log(data)
    this.setState({allTrans: data, loading:false });
})
.catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
       }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
}

fetchTrans = () =>{
  const {data} = this.state
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
  };
  fetch(getConfig('transaction_export'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  // console.log(data)
  this.setState({trans: data, loading:false });
})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false, err : "internet error" });
  console.error('There was an error!', error);
});
}

fetchUsers = () =>{
  const {data} = this.state
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
  };
  fetch(getConfig('transactions'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  // console.log(data)
  this.setState({users: data.data, all:data, loading:false });
})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false, err : "internet error" });
  console.error('There was an error!', error);
});
}

searchChange(event) {
  const { name, value } = event.target;
  const { search, users, all } = this.state;
  
  this.setState({ search: value, users: value === "" ? all : all.filter((q)=>
  q.from_date.toLowerCase().indexOf(value.toLowerCase())  !== -1 
  || q.to_date.toLowerCase().indexOf(value.toLowerCase())  !== -1 
  // || q.frequency.toLowerCase().indexOf(value.toLowerCase())  !== -1 
  )});
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
  if(name === "from_date"||name === "to_date"){
    this.setState({ data: { ...data, [name]: value } });
  }else{
    this.setState({ data: { ...data, [name]: value }, loading:true},()=>{
      this.fetchUsers();
      this.fetchAllTrans()
      this.fetchTrans()
    });
  }
}

handleSubmit(event) {
  event.preventDefault();
  this.fetchUsers()
  this.fetchAllTrans()
  this.fetchTrans()
  this.setState({check:true})
} 


render(){
  const {theme} = this.props
  const {users, check, trans, allTrans, loading, data, all} = this.state
  console.log(trans);console.log(allTrans);
    return (
      <div style={{padding: theme.spacing(3)}}>
        <div style={{float: 'right'}}>
          <ExportButton users={trans} title='Export Transactions' />
        </div>
        <Grid container spacing={4} >
          <Grid item lg={8} md={8} sm={12} xs={12}>
              <UsersToolbar handleSubmit={this.handleSubmit} handleChange={this.handleChange}/>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <TextField
              style={{width:"100%"}}
              select
              label="search"
              name="new_search"
              variant="outlined"
              margin="dense"
              value={data.new_search}
              onChange={this.handleChange}>
                <MenuItem value={""}>Select an option</MenuItem>
                <MenuItem value={"Bank Account"}>Bank Account</MenuItem>
                <MenuItem value={"Wallet"}> Wallet</MenuItem>
                <MenuItem value={"Regular Savings"}>Regular Savings</MenuItem>
                <MenuItem value={"Target Savings"}> Target Savings</MenuItem>
                <MenuItem value={"Save To Loan"}> Save To Loan</MenuItem>
                <MenuItem value={"Loan"}> Loan</MenuItem>
                <MenuItem value={"Market Investment"}> Market Investment</MenuItem>
                <MenuItem value={"Halal Financing"}> Halal Financing</MenuItem>
                <MenuItem value={"Credit"}>Credit</MenuItem>
                <MenuItem value={"Debit"}> Debit</MenuItem>
            </TextField>
            </Grid>
            {/* {check &&
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ExportButton users={trans} title='Export Filtered Transactions' />
              </Grid>
            } */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TransactionTable users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading}/>
          </Grid>
        </Grid>
      </div>
  
    );
  };
}
  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
// export default withStyles({}, { withTheme: true })(Dashboard1);
const actionCreators = {
  saveWallet: adminActions.saveWallet,
  logout: adminActions.logout,
  transactions: adminActions.transactions,
  
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(Transactions))
);