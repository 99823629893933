import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl } from '../../../redux/config/config'
import { authHeader} from '../../../redux/logic';
import { Grid, Button, TextField, Card, Typography, MenuItem} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import swal from 'sweetalert';

class PostDues extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    this.state ={
      data:[
        {
        id:0,
        dues_id:"",
        user_id:"",
        amount:0
      },],
      bank:{
        bank_id:"",
        bank_name:"",
        payment_date:"",
        deposit_amount:0,
      },
      banks:[],
      users: [],
      packages:[],
      packages_name:[],
      all: [],
      dues: [],
      search: "",
      loading: true,
      open:false,
      search:{term:""},
      total:0
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchBanks = this.fetchBanks.bind(this);

  }
componentDidMount(){
  this.fetchBanks()
}

fetchBanks = () =>{
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getAllBanks'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({banks: data});
    })
    .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
    });
}

fetchUsers = (search) =>{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(search),
    };
    fetch(getConfig('getAllUsersAutoComplete'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    this.setState({users: data});
})
.catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
       }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
}

fetchUsersPackage = (search) =>{
  const {packages} = this.state
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(search),
    };
    fetch(getConfig('getAllDues'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    console.log(data)
    var packs = [];
    data.products.forEach(prod => {
      if(prod.id !== 0){
        packs.push(prod.name)
      }
    });
    this.setState({all: packs});
    console.log(data)
  })
  .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
  });
}
fetchDues = () =>{
  const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig('getAllDues'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }console.log(data)
  if(data.success === false){
      this.setState({dues: [], loading:false});
  }else{
      this.setState({dues: data.data, loading:false});
  }
  })
  .catch(error => {
  if (error === "Unauthorized") {
      this.props.logout()
  }
});
}

handleChange(event, id) {
  const { name, value } = event.target;
  console.log(value)
  const { data, dues } = this.state;
  // const elementsIndex = data.findIndex(element => element.id === id )
  let newArray = [...data]
   if(name === "due_name"){
    dues.forEach((dat)=> {
        if(dat.due_name === value){
          newArray[id] = {...newArray[id], dues_id:dat.id, [name]: value}
        }if(dat.due_name === value){
          newArray[id] = {...newArray[id], amount:dat.due_fees, [name]: value}
        }
      });
  }else{
    newArray[id] = {...newArray[id], [name]: value}
  }console.log(data)
  this.setState({data: newArray});
}


// handleSubmit(event) {
//   event.preventDefault();
//   const {data, bank} = this.state
//   let total = 0
//   data.forEach(d => {
//     total += Number(d.amount);
//   });
//   if(bank.deposit_amount >= total){
//     let dat = {bank:[bank], data};
//     console.log(dat)
//     if( data.length > 0){
//       this.props.saveMultipleDues(dat)
//     }else{
//       swal("Atleast one transaction has to be filled")
//     }
//   }else{
//     swal("Deposited amount is not enough to continue this transaction(s)")
//   }  
// }

handleSubmit(event) {
  event.preventDefault();
  const {data, bank} = this.state
  let total = 0
  data.forEach(d => {
    total += Number(d.amount);
  });
  if(bank.deposit_amount >= total){
    let dat = {bank:[bank], data};
    console.log(dat)
    if( data.length > 0){ 
      swal("Are you sure you want to make this posting?", {
        buttons: {
          cancel: "Cancel",
          
          confirm: {
            text: "Confirm",
            value: "catch"}
        },
      })
      .then((value) => {
        switch (value) {
          case "catch":
            this.props.saveMultipleDues(dat);
            swal("Loading...", {
              buttons: false
            });
            break;
      
          default:
            swal("cancelled!");
        }
      });      
    }else{
      swal("Atleast one transaction has to be filled")
    }
  }else{
    swal("Deposited amount is not enough to continue this transaction(s)")
  }
  
}

handleIncrement = () =>{
  const {data} = this.state
  let id = data.length
  this.setState({
    data:[...data, {
      id:0,
      dues_id:"",
      user_id:"",
      amount:0
    },]
  })
  console.log(data)
}
handleRemove = (id) =>{
  const {data} = this.state
  data.splice(id, 1)
  this.setState({
    data:data
  })
}

handleBankChange = (event) =>{
  event.preventDefault();
  const {bank, banks} = this.state
  const {name, value} = event.target
  if(name === "bank_name"){
    banks.forEach(b => {
      if(b.bank_name === value){
        this.setState({bank:{...bank, [name]:value, bank_id:b.id}})
      }
    });
  }else{
    this.setState({bank:{...bank, [name]:value}})
  }
}

handleChangeUser = (event, values, id) =>{
  const {name, value } = event.target;
    const { data, users } = this.state;
    let newArray = [...data];
  this.fetchUsers({term: value});
  users.forEach(user => {
    if(values === user.id + user.first_name + " " + user.last_name){
      this.fetchDues({user_id:user.id});
      const elementsIndex = data.findIndex((element, i) => i === id )
      newArray[elementsIndex] = {...newArray[elementsIndex], user_id: user.id}
    }
  });
  this.setState({data: newArray});
}

render(){
  const style = {
    cards:{
      padding:10,
      margin:10
    },
    title: {
      marginTop: 5,
      marginBottom:25,
      textAlign:"center",
      color: "Green"
    }
  }
  const {theme} = this.props
  const {users, all, dues, packages_name, banks, bank, loading, search, handleSubmit, handleChange, index, data, open, searchChange} = this.state
  
    return (
      <div style={{padding: theme.spacing(3)}}>
      <ValidatorForm onSubmit={this.handleSubmit} >
        <Card elevation={5} style={style.cards}>
            <Typography
              style={style.title}
              variant="h2">
                 Post Dues
            </Typography>
          <Grid container spacing={2} >
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  label="Bank Name"
                  fullWidth
                  name="bank_name"
                  margin="normal"
                  variant="outlined"
                  value={bank.bank_name}
                  validators={[
                      "required"
                    ]}
                  onChange={this.handleBankChange}
                  select>
                    <MenuItem value={bank.bank_name}>{bank.bank_name}</MenuItem>
                    {banks.map((option, index) => (
                      <MenuItem key={index} value={option.bank_name}>
                        {option.bank_name}
                      </MenuItem>
                    ))}
                  </TextValidator>
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter amount value"
                  label=" Deposited Amount"
                  name="deposit_amount"
                  onChange={this.handleBankChange}
                  value={bank.deposit_amount}
                  type="number"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                />
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={4}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter payment date"
                  name="payment_date"
                  onChange={this.handleBankChange}
                  value={bank.payment_date}
                  type="date"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                />
            </Grid>
          </Grid>
          {data.map((dat, index) =>(
          <Grid container spacing={2} key={index}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(event, value) => this.handleChangeUser(event, value, index)}
                options={users.map((option) =>option.id + option.first_name + " " + option.last_name )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event, value) => this.handleChangeUser(event, value, index)}
                    label="Search users"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <TextValidator
                  label="Dues Name"
                  fullWidth
                  name="due_name"
                  margin="normal"
                  variant="outlined"
                  value={dat.due_name}
                  validators={[
                      "required"
                    ]}
                  onChange={(e)=>this.handleChange(e, index)}
                  select>
                    <MenuItem value={dues.due_name}>{dues.due_name}</MenuItem>
                    {dues.map((option, i) => (
                      <MenuItem key={i} value={option.due_name}>
                        {option.due_name}
                      </MenuItem>
                    ))}
                </TextValidator>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <TextValidator
                  fullWidth
                  margin="normal"
                  helperText="Enter amount value"
                  label="Amount"
                  name="amount"
                  readOnly
                  value={dat.amount}
                  type="number"
                  variant="outlined"
                  validators={[
                      "required"
                    ]}
                /> 
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              {index !== 0 &&<Button margin="normal" type="button" variant="contained" color="primary" onClick={()=>this.handleRemove(index)} >Remove</Button>}
            </Grid>
          </Grid>))}
          <Grid container direction="row" justify="space-between" alignItems="center">
              <Button variant="contained" type="button" color="secondary" onClick={this.handleIncrement} >Add New</Button>
              {this.props.savings &&
                <img img alt=""  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
              <Button variant="contained" type="submit" color="primary">Submit</Button>
          </Grid>
        </Card>
      </ValidatorForm>
      </div>
  
    );
  };
}
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
// export default withStyles({}, { withTheme: true })(Dashboard1);
const actionCreators = {
  saveMultipleDues: adminActions.saveMultipleDues,
  logout: adminActions.logout,
  transactions: adminActions.transactions,
  
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(PostDues))
);