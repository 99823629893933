import React, {useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { getConfig, numberFormat, checkToken } from '../../../../redux/config/config';
import { authHeader, history } from '../../../../redux/logic';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  CircularProgress,
  CardHeader
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const PayOptionTable = props => {
  const { className, handleSubmit, investments, ...rest} = props;
  const {savings} = props;
  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

const [open, setOpen] = useState(false);
const [isLoading, setIsLoading] = useState(true);
const [loading, setLoading] = useState(true);

const [details, setDetails] = useState({
  name:'',
  request_type : '2'
});
const [paymentName, setPaymentName] = useState([]);

useEffect(() => {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig('paymentOption'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
    if(data.success === false){
      setPaymentName([]);
    }else{
      setPaymentName(data.data);
    }
    setLoading(false);
  })
  .catch(error => {
    if (error === "Unauthorized") {
      props.logout()
    }
  });
}, [])

// const handleOpen = (id) => {
//   setIsLoading(true)
//   setOpen(true);
//   let user = JSON.parse(localStorage.getItem('admin'));
//   const requestOptions = {
//     method: 'POST',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//   };
//   fetch(getConfig('AddPaymentOptions') + id + `?token=`+user.token, requestOptions)
//   .then(async response => {
//   const data = await response.json();
//   if (!response.ok) {
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//   }
//   console.log(data)
//   setDetails(data.data)
//   setIsLoading(false)
//   // setName(data[1].package_name && data[2].commission)
//   })
//   .catch(error => {
//   if (error === "Unauthorized") {
//       history.push('/login');
//       }
//       setIsLoading(false)
//   console.error('There was an error!', error);
// });
// };

const handleOpen = (id) => {
  setIsLoading(false)
  setOpen(true);
  const data = paymentName.find(users => users.id === id);
  setDetails(data)
}

const handleEnable = (id, status) => {
  let msg = status === 1 ? "disable":"enable";
  swal({
    title: "Are you sure you want to "+ msg,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      if(status === 1){
        const data = paymentName.find(users => users.id === id);
        const newData = {...data, request_type:3} 
        props.UpdatePaymentOptions(newData);
      }else{
        const data = paymentName.find(users => users.id === id);
        const newData = {...data, request_type:4} 
        props.UpdatePaymentOptions(newData);
      }
    swal("Loading...",{   
        buttons:false
      });
    }
  });
  }
  
const handleSubmitEdit = (event) => {
  event.preventDefault();
  if (details.name) {
    const data = details
    const newData = {...data, request_type:2}
    // console.log(newData)
    props.UpdatePaymentOptions(newData);
  }
}

const handleChangeEdit = (e) => {
  e.persist();
    setDetails(details=>({ ...details, [e.target.name]:e.target.value}))
  }

const handleClose = () => {
  setOpen(false);
};

return (   
    <div style={{marginTop: 20}}>
       {/* Modal */}
       < Dialog
          open={open}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle  id="alert-dialog-slide-title">Update Payment Option</DialogTitle>  
          <Divider />     
          <DialogContent>
            <CardContent className={classes.content}>
              {isLoading? <Typography>Loading...</Typography>:
              <form  noValidate autoComplete="off" onSubmit={handleSubmitEdit}>
                  <Grid style={{ marginTop: '1rem' }}>
                    <Typography>Payment option Name</Typography>
                  </Grid>
                  <Grid>
                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={handleChangeEdit}
                      name="name"   
                      value={details.name} 
                      readOnly
                      margin="dense"
                      variant="outlined"
                      >
                    </TextField>
                  </Grid>
                  <Grid item md={10} xs={10} style={{ marginTop: '1rem' }}>
                    {savings && <CircularProgress />}
                    <Button color="primary" onClick={handleSubmitEdit} variant="contained" >
                      Update 
                    </Button>
                    <Button onClick={handleClose} variant="contained" 
                    style={{marginLeft:10, color:'white', backgroundColor:'red'}}>
                      Cancel
                    </Button>
                  </Grid>
              </form>}
            </CardContent>
          </DialogContent>
        </Dialog>
        {/* Modal */}
      <Card className={clsx(classes.root, className)} >
        <CardHeader subheader="Payment Option Settings" title="Payment Option Settings"/>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Option</TableCell>
                    <TableCell>Enter By</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading?
                <TableRow><TableCell><CircularProgress /></TableCell></TableRow>: 
                paymentName.length !== 0 ?
                paymentName.slice(page * rowsPerPage, page* rowsPerPage + rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.entered_by}</TableCell>
                    <TableCell>
                      {moment(user.created_at).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Grid style={{display:'flex'}}>
                        <Button color="primary" variant="contained" 
                        onClick={()=> handleOpen(user.id)}
                        > Edit</Button>
                        {user.status === 1 ?
                          <Button  color="primary" style={{marginLeft:10, background:'red', color:'#fff'}} variant="contained" 
                          onClick={()=> handleEnable(user.id, user.status)} 
                          > Disable</Button>:
                          <Button  color="primary" style={{marginLeft:10, background:'green', color:'#fff'}} variant="contained" 
                          onClick={()=> handleEnable(user.id, user.status)} 
                          > Enable</Button>}
                      </Grid>
                      </TableCell>
                      <TableCell>
                  </TableCell>
                  </TableRow>
                )):
                <TableRow>
                <TableCell colSpan={5} style={{textAlign:"center"}}>
                    No Record Found
                  </TableCell>                
                </TableRow>
                }
              </TableBody>
            </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={paymentName.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      
    </div>

  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  modifyTargetCommission: adminActions.modifyTargetCommission,
  UpdatePaymentOptions: adminActions.UpdatePaymentOptions,
};
// export default UsersTable;

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(PayOptionTable))
);