import { userConstants } from "../_constants";
import { adminService } from "../service/admin.service";
import { alertActions } from "./";
import { history } from "../logic";
import { checkLastUrl } from "redux/config/config";

export const adminActions = {
  validateLogin,
  adminlogin,
  logout,
  adminregister,
  lostpassword,
  resetpassword,
  disableUsers,
  enableUsers,
  recoverPassword,
  adminChangePassword,
  adminAddMarket,
  adminUpdateMarketCategory,
  adminUpdateMarketNews,
  updateAdmin,
  disableAdmin,
  enableAdmin,
  makeWithdrawal,
  deleteUser,
  modifyTargetCommission,
  AddPaymentOptions,
  UpdatePaymentOptions,
  EditDues,
  adminUpdateHalalNews,
  regularSavingsTransactionsAdmin,
  targetTransactionsAdmin,
  saveLoanTransactionsAdmin,
  adminUpdateHalalCategory,
  admindeleteCategory,
  admindeleteMarketNews,
  deleteTargetCommission,
  admindeleteHalalNews,
  admindeleteHalaiCategory,
  adminAddMarketCategory,
  adminAddHalalCategory,
  adminAddHalal,
  adminUpdateMarket,
  adminUpdateHalal,
  adminAddHalalNews,
  addAdmin,
  adminAddMarketNews,
  addTargetCommission,
  addDues,
  adminApproveLoanz,
  disburstLoan,
  getAllUsersAutoComplete,
  saveMultipleAcct,
  saveMultipleDues,
  addNewBank,
  updateBank,
  createUserLoan,
  addUser,
  createTargetAcct,
  approveOrder,
  declineOrder,
  deliverOrder,
  addProductCategory,
  deleteProductCategory,
  addPrice,
  addProduct,
  deleteProduct,
  editProduct,
  addCart,
  createOrder,
  addBusinessPrice,
  approveBusinessRequest,
  cancelBusinessRequest,
  deleteActivities,
  disburseBusinessLoan,
  accountSettings,
  createRole,
  updateRole,
  activateOnline,
  activateUser
};

function validateLogin(email, password) {
	return (dispatch) => {
	  dispatch(request({ email }));
	  adminService.validateLogin(email, password).then(
		(user) => {
		  if(user.status){
			  dispatch(success(user));
		  }else{
			  dispatch(alertActions.error(user.message));
		  }
		},
		(error) => {
		  dispatch(failure(error.toString()));
		  dispatch(alertActions.clear(error.toString()));
		}
	  );
	};
  
	function request(user) {
	  return { type: userConstants.LOGIN_REQUEST, user };
	}
	function success(user) {
	  return { type: userConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
	  return { type: userConstants.LOGIN_FAILURE, error };
	}
  }

function adminlogin(data) {
  return (dispatch) => {
    dispatch(request( data.email ));
    adminService.adminlogin(data).then(
      (user) => {
        
        if(user.status){
          dispatch(success(user));
          // history.push("/dashboard");
          checkLastUrl()
        }else{
          dispatch(success(user));
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getAllUsersAutoComplete(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    adminService.getAllUsersAutoComplete(username, password).then(
      (user) => {
        if(user.status){
          dispatch(success(user));
          history.push("/apply");
        }else{
          dispatch(success(user));
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function makeWithdrawal(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.makeWithdrawal(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          // history.push("/posting");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function accountSettings(data) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.accountSettings(data).then(
      (user) => {
        dispatch(success(user));
        if(user.success === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/settings");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function createRole(data) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.createRole(data).then(
      (user) => {
        dispatch(success(user));
        if(user.success === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/settings");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function updateRole(data) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.updateRole(data).then(
      (user) => {
        dispatch(success(user));
        if(user.success === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/settings");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function saveMultipleDues(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.saveMultipleDues(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/posting");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function saveMultipleAcct(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.saveMultipleAcct(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          // history.push("/posting");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addPrice(price, id) {
  return (dispatch) => {
    dispatch(request({ price }));
    adminService.addPrice(price, id).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/products/request");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addCart(data) {
  return (dispatch) => {
    dispatch(request(data));
    adminService.addCart(data).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/products/create");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addProduct(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.addProduct(user).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function deleteProduct(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.deleteProduct(user).then(
      (user) => {
        dispatch(success());
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function editProduct(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.editProduct(user).then(
      (user) => {
        dispatch(success());
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function createOrder(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.createOrder(user).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function createUserLoan(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.createUserLoan(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/transactions");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addUser(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.addUser(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/users");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function createTargetAcct(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.createTargetAcct(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/targetsavings_tab");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function declineOrder(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.declineOrder(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/products/order");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function approveOrder(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.approveOrder(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/products/order");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function deliverOrder(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.deliverOrder(user).then(
      (user) => {
        dispatch(success(user));
        if(user.success){
          history.push("/products/order");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addProductCategory(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.addProductCategory(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/products/category");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function deleteProductCategory(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.deleteProductCategory(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/products/category");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function deleteActivities(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.deleteActivities(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/logs");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function approveBusinessRequest(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.approveBusinessRequest(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/business/approved");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addBusinessPrice(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.addBusinessPrice(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/business");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function cancelBusinessRequest(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.cancelBusinessRequest(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/business/approval");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function addNewBank(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.addNewBank(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/settings");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function updateBank(user) {
  return (dispatch) => {
    dispatch(request({ user }));
    adminService.updateBank(user).then(
      (user) => {
        dispatch(success(user));
        if(user.status){
          history.push("/settings");
          dispatch(alertActions.success(user.message));
        }else{
          dispatch(alertActions.error(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function adminAddMarket(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.adminAddMarket(user).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message) );
        
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateMarket(user, id) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.adminUpdateMarket(user, id).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminAddHalal(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.adminAddHalal(user).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateHalal(user, id) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.adminUpdateHalal(user, id).then(
      (user) => {
        dispatch(success());
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminApproveLoanz(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.adminApproveLoanz(user).then(
      (user) => {
        dispatch(success());
        history.push("/loan");
        
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function disburstLoan(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.disburstLoan(user).then(
      (user) => {
        dispatch(success());
        history.push("/loan");
        dispatch( alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function disburseBusinessLoan(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.disburseBusinessLoan(user).then(
      (user) => {
        dispatch(success());
        if(user.status === false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
        history.push("/business/approval");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function addTargetCommission(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.addTargetCommission(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function addDues(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.addDues(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminAddMarketNews(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminAddMarketNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function addAdmin(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.addAdmin(user).then(
      (user) => {
        dispatch(success());
        history.push("/admin");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminAddHalalNews(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminAddHalalNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminAddMarketCategory(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminAddMarketCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_categories");
        dispatch(
          alertActions.success("New Market Investment Added")
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateMarketCategory(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminUpdateMarketCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_categories");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateMarketNews(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminUpdateMarketNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function updateAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.updateAdmin(user).then(
      (user) => {
        dispatch(success());
        history.push("/admin");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function disableAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.disableAdmin(user).then(
      (user) => {
        dispatch(success());
        history.push("/admin");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function deleteUser(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.deleteUser(user).then(
      (user) => {
        dispatch(success());
        history.push("/users");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function enableAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.enableAdmin(user).then(
      (user) => {
        dispatch(success());
        history.push("/admin");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function modifyTargetCommission(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.modifyTargetCommission(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function AddPaymentOptions(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.AddPaymentOptions(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function UpdatePaymentOptions(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.UpdatePaymentOptions(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function EditDues(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.EditDues(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateHalalNews(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminUpdateHalalNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function regularSavingsTransactionsAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.regularSavingsTransactionsAdmin(user).then(
      (user) => {
        dispatch(success());
        // history.push("/halal_news");
        if(user.length === 0){
          dispatch(alertActions.success("No records found"));
        }else{
          console.log(user)
          return user
        }
        // ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function saveLoanTransactionsAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.saveLoanTransactionsAdmin(user).then(
      (user) => {
        dispatch(success());
        // history.push("/halal_news");
        if(user.length === 0){
          dispatch(alertActions.success("No records found"));
        }else{
          console.log(user)
          return user
        }
        // ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function targetTransactionsAdmin(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.targetTransactionsAdmin(user).then(
      (user) => {
        dispatch(success());
        // history.push("/halal_news");
        if(user.length === 0){
          dispatch(alertActions.success("No records found"));
        }else{
          console.log(user)
          return user
        }
        // ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminUpdateHalalCategory(user) {
  console.log(user)
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminUpdateHalalCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_categories");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function admindeleteCategory(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.admindeleteCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_categories");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function disableUsers(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.disableUsers(user).then(
      (user) => {
        dispatch(success());
        history.push("/users");
        dispatch(
          alertActions.success(user.message)
        );
        // ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function enableUsers(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.enableUsers(user).then(
      (user) => {
        dispatch(success());
        history.push("/users");
        dispatch(
          alertActions.success(user.message)
        );
        // ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function admindeleteMarketNews(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.admindeleteMarketNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/market_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function deleteTargetCommission(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.deleteTargetCommission(user).then(
      (user) => {
        dispatch(success());
        history.push("/settings");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function admindeleteHalalNews(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.admindeleteHalalNews(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_news");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function admindeleteHalaiCategory(user) {
  
  return (dispatch) => {
    dispatch(request(user));
    adminService.admindeleteHalaiCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_categories");
        dispatch(
          alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function adminAddHalalCategory(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminAddHalalCategory(user).then(
      (user) => {
        dispatch(success());
        history.push("/halal_categories");
        dispatch(
          alertActions.success("New Market Investment Added")
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function activateOnline(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.activateOnline(user).then(
      (user) => {
        dispatch(success());
        if(user.status == false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function activateUser(user) {
  return (dispatch) => {
    dispatch(request(user));
    adminService.activateUser(user).then(
      (user) => {
        dispatch(success());
        if(user.status == false){
          dispatch(alertActions.error(user.message));
        }else{
          dispatch(alertActions.success(user.message));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function logout() {
  adminService.logout();
  return { type: userConstants.LOGOUT };
}

function adminregister(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminregister(user).then(
      (user) => {
        dispatch(success());
        history.push("/adminlogin");
        dispatch(alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function lostpassword(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.lostpassword(user).then(
      (user) => {
        dispatch(success());
        history.push("/recoverysuccess");
        dispatch(alertActions.success("Password recovered successful"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function resetpassword(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.resetpassword(user).then(
      (user) => {
        dispatch(success());
        history.push("/");
        dispatch(alertActions.success("Password reset successful"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}


function recoverPassword(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.recoverPassword(user).then(
      (user) => {
        dispatch(success());
        history.push("/");
        dispatch(alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function adminChangePassword(user) {
  return (dispatch) => {
    dispatch(request(user));

    adminService.adminChangePassword(user).then(
      (user) => {
        dispatch(success());
        history.push("/account");
        dispatch(alertActions.success(user.message)
        );
        ;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function request(user) {
  return { type: userConstants.SAVINGS_REQUEST, user };
}
function success(user) {
  return { type: userConstants.SAVINGS_SUCCESS, user };
}
function failure(error) {
  return { type: userConstants.SAVINGS_FAILURE, error };
}