import React,{Component} from 'react';
import SimpleTabs from './PostingTabPanel';

class CustomPostDue extends Component{

render(){
  return (
    <SimpleTabs value={1}/>
  );
}
}


export default CustomPostDue