import React,{Component} from 'react';
import CustomTabs from './CustomTabs';

class CustomBankTab extends Component{

render(){
    return (
      <CustomTabs value={2}/>
    );
  }
}

export default CustomBankTab