import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import swal from 'sweetalert'
import { getConfig} from '../../../../redux/config/config';
import { authHeader, history } from '../../../../redux/logic';
import { Notifications} from '../../components';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Divider,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  CardHeader
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  bash:{
    width: 1020
  },
  formLabel: {
    alignItems: 'center',
    paddingLeft:10,
    paddingTop:15
  },
  formLabels: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    alignItems: 'right'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const BanksTable = props => {
  const { className, investments, ...rest} = props;
  const {savings} = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showPay, setShowPay] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('getAllBanks'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
      console.log(data)
      if(data.success === false){
        setBanks([]);
      }else{
        setBanks(data);
      }
      setLoading(false);
    })
    .catch(error => {
      if (error === "Unauthorized") {
        props.logout()
      }
    });
  }, [])

const handlePageChange = (event, page) => {
  setPage(page);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(event.target.value);
};


const handleOpen = (id) => {
  setIsLoading(true)
  setOpen(true);
  let user = JSON.parse(localStorage.getItem('admin'));
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig('getSingleBank') + id + `?token=`+user.token, requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  console.log(data)
  setDetails(data)
  setIsLoading(false)
  })
  .catch(error => {
  if (error === "Unauthorized") {
      history.push('/login');
      }
      setIsLoading(false)
    console.error('There was an error!', error);
});
};

// const handleDelete = (id) => {
// swal({
//   title: "Are you sure?",
//   text: "Once deleted, you will not be able to recover this file!",
//   icon: "warning",
//   buttons: true,
//   dangerMode: true,
// })
// .then((willDelete) => {
//   if (willDelete) {
//     props.deleteTargetCommission(id);
//     swal("Loading...",{   
//       buttons:false
//     });
//   }
// });
// }
  
// const handleSubmitEdit = (event) => {
//   event.preventDefault();
//   if (details.package_name && details.commission) { 
//     console.log(details)   
//     // props.modifyTargetCommission(details);
//    }
// }

const handleClose = () => {
  setOpen(false);
};
// const handleChangeEdit = (e) => {
// e.persist();
//   setDetails(details=>({ ...details, [e.target.name]:e.target.value}))
// }
const handleCreate = (val) =>{
  setShow(val)
}
 return (
  <div style={{padding: 20}}>
    <div style={{height: '42px', alignItems: 'center', marginTop: 10}}>
      <div style={{float:'right'}}>
        <div className="row">
          <span className="spacer" />
          <Button
            color="primary"
            variant="contained"
            onClick={()=>handleCreate(true)}>
            Add Bank 
          </Button>
        </div>
      </div>
    </div>
    <div style={{marginTop: 20}}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader subheader="Bank Table" title="All Banks"/>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Account Number</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {loading? <CircularProgress />: 
                <TableBody>
                {banks.length !== 0 ?
                banks.slice(page * rowsPerPage, page* rowsPerPage + rowsPerPage).map(bank => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={bank.id}
                  >
                    <TableCell>{bank.account_name}</TableCell>
                    <TableCell>{bank.account_no}</TableCell>
                    <TableCell>{bank.bank_name}</TableCell>
                    <TableCell>{moment(bank.created_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>
                      <Grid style={{display:'flex'}}>
                          <Button color="primary" variant="contained" 
                          onClick={()=> handleOpen(bank.id)}
                          > Edit</Button>
                          </Grid>
                      </TableCell>
                  </TableRow>
                )):
                <TableRow>
                  <TableCell style={{textAlign:"center"}}>
                    No Record Found
                  </TableCell>                
                </TableRow>}
              </TableBody>}
            </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={banks.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
    <Notifications add={false} show={show} handleClose={()=>handleCreate(false)}/>
    {/* Modal */}
    < Dialog
      open={open}
      fullWidth={true}
      maxWidth = {'xs'}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle  id="alert-dialog-slide-title">Update Bank Details</DialogTitle>  
      <Divider />     
      <DialogContent>
        <CardContent className={classes.content}>
        {isLoading? <Typography>Loading...</Typography>:
        <Notifications add={true} update={details}/> 
        }
          </CardContent>        
          <Divider /> 
        <DialogActions> 
        </DialogActions>      
        {/* </DialogContentText> */}        
      </DialogContent>
    </Dialog>
    {/* Modal */}
  </div>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}

const actionCreators = {
  logout: adminActions.logout,
  modifyTargetCommission: adminActions.modifyTargetCommission,
  deleteTargetCommission: adminActions.deleteTargetCommission,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,actionCreators)(BanksTable))
);