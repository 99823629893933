import React, { useState, Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography, CardContent, Divider, DialogContent, DialogTitle, Dialog, TextField, } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { authHeader, history } from 'redux/logic';
import { SearchInput } from 'components';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ProductTable from './components/ProductTable';
import { getConfig } from 'redux/config/config';
import { adminActions } from 'redux/action';


class CancelledList extends Component{
  constructor(props){
    super(props)
    this.state = {
        data: [],
        loading:true,
        all: [],
        search:""
    }
    this.fetchOrders = this.fetchOrders.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
    this.searchChange = this.searchChange.bind(this);

  }

componentDidMount(){
  this.fetchOrders()
}

fetchOrders = () =>{
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body:JSON.stringify({search_term:this.state.search})
    };
  fetch(getConfig('pendingBusinessRequest'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({data: [], loading:false});
    }else{
      let newArray = []
      data.data.forEach(d => {
        if(d.request_status === 9){
          newArray.push(d)
          this.setState({data: newArray, all:data, loading:false});
        }else{
          this.setState({loading:false});
        }
      });
    }
})
.catch(error => {
    if (error === "Unauthorized") {
        this.props.logout();
       }
      this.setState({loading:false });
  });
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

searchChange(event) {
  const { value } = event.target;
  this.setState({  search: value, loading:true},()=>{ this.fetchOrders() });
}

render(){
  let {theme} = this.props
  const { loading, data,search, all} = this.state
  return (
    <div style={{padding: theme.spacing(3)}}>
      <div style={{height: '42px' ,alignItems: 'center', marginTop: theme.spacing(1)}}>
        <TextField
          style={{width:"50%"}}
          label="search username"
          margin="dense"
          variant="outlined"
          value={search}
          onChange={this.searchChange}
          style={{marginRight: theme.spacing(1)}}
          placeholder="Search"
        />
      </div>
      <div style={{marginTop: theme.spacing(2)}}>
        <ProductTable table={"cancelled"} data={data} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} />
      </div>
    </div>
    );
  };
}

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  addUser: adminActions.addUser,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(CancelledList))
);