import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Button } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import OfficeBagIcon from '@material-ui/icons/CardTravel';
import { adminActions } from "../../../../redux/action";
import { withStyles } from "@material-ui/styles";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MoneyIcon from '@material-ui/icons/Money';
import SettingsIcon from '@material-ui/icons/Settings';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { Profile, SidebarNav, UpgradePlan } from './components';
import { BusinessCenter } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    backgroundColor:"#fff"
  },
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <HomeIcon />
    },
    {
      title: 'Admins',
      href: '/admin',
      icon: <SupervisedUserCircleIcon />,
      role:1
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />,
      role:1
    },
    {
      title: 'Savings',
      href: '/savings_tab',
      icon: <MoneyIcon />,
    },
    {
      title: 'Loan',
      href: '/loan',
      icon: <AccountBoxIcon />
    },
    // {
    //   title: 'Postings',
    //   href: '/apply',
    //   icon: <AccountBoxIcon />
    // },    
    {
      title: 'Postings',
      href: '/posting',
      icon: <AccountBoxIcon />
    },
    // {
    //   title: 'Post Dues',
    //   href: '/dues',
    //   icon: <AccountBoxIcon />
    // },
    {
      title: 'Product Fin.',
      href: '/products',
      icon: <ShoppingCartIcon />
    },
    {
      title: 'Business Fin.',
      href: '/business',
      icon: <OfficeBagIcon />
    },
    {
      title: 'Withdrawals',
      href: '/pending-withdrawal',
      icon: <BusinessCenter />
    },
    {
      title: 'Transactions',
      href: '/transactions',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Activity Log',
      href: '/logs',
      icon: <AssignmentIcon />
    },
    {
      title: 'Setup',
      href: '/account/system',
      icon: <PermDataSettingIcon />
    },
    {
      title: 'GL & Banking',
      href: '/account/GL',
      icon: <AccountBalanceIcon />
    },
    {
      title: 'Fixed Assets',
      href: '/account/assets ',
      icon: <GpsFixedIcon />
    },
    {
      title: 'Dimensions',
      href: '/account/proj',
      icon: <AssessmentIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    },
  ];

  const logout = () => {
    props.logout()
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        <div className="px-4">
        <Button
          startIcon={<PowerSettingsNewIcon />}
          variant="contained"
          size="small"
          color="primary"
          style={{marginTop:10, color:'#fff',borderColor:'#fff'}}
          onClick={logout}
        >
        Logout
        </Button>
        </div>
        {/* <UpgradePlan /> */}
      </div>     
    
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

// export default Sidebar;
// export default UserList;
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
// export default withStyles({}, { withTheme: true })(Dashboard1);
const actionCreators = {
  saveWallet: adminActions.saveWallet,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(Sidebar))
);
