import React, { Component } from 'react'
import CustomPostDue from '../components/Tabs/CustomPostDue'
import SimpleTabs from '../components/Tabs/PostingTabPanel'

class PostDuesTab extends Component {
    render() {
        return (
            <div>
              <CustomPostDue />
            </div>
        )
    }
}

  export default PostDuesTab