import React,{ Component} from 'react';
import CustomTabs from './CustomTabs';

class CustomCommissionTab extends Component{
  
render(){
    return (
      <CustomTabs value={0}/>
    );
  }
}

export default CustomCommissionTab