import React, {Component } from 'react';
import { Button, Grid, TextField, MenuItem, Divider, Dialog, DialogTitle, DialogContent, CardContent, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, setLastUrl} from '../../../redux/config/config'
import { authHeader } from '../../../redux/logic';
import { UsersTable } from '../components/Savings';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@material-ui/lab/Autocomplete';


class TargetSavings extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    this.state ={
      data:{
        new_search:"",
      },
      acct:{
        amount : "",
        targeted_amount : "",
        taret_name:"",
        frequency : "",
        transaction_time : "",
        transaction_day : "",
        transaction_month : 0,
        start_date : "",
        end_date : "",
        user_id : "",
    },
      users: [],
      usersData: [],
      all: [],
      type: [],
      search: "",
      loading: true,
      open:false,
	  show:false,
	  current_index:1
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAcct = this.handleChangeAcct.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
  }

componentDidMount(){
  this.fetchUsers();
}

fetchUserData = (search) =>{
  const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(search),
  };
  fetch(getConfig('getAllUsersAutoComplete'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  if(data.success === false){
	this.setState({usersData: [], loading:false });
  }else{
	this.setState({usersData: data});
  }
})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false, err : "internet error" });
  console.error('There was an error!', error);
});
}

fetchUsers = () =>{
  const {data} = this.state
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    fetch(getConfig('getAllTargetSavings'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({categories:data.data, users: data.data.filter((ne) =>ne.status == 1), all:data, loading:false });
    }
})
.catch(error => {
    if (error === "Unauthorized") {
      this.props.logout()
    }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
}

handleOpen = () =>{
  this.setState({show: true})
}

handleClose = () =>{
  this.setState({show: false})
}

handleSubmit =()=>{
  const {acct} = this.state
  if(acct.amount && acct.targeted_amount && acct.start_date && acct.end_date &&acct.transaction_day && acct.transaction_time && acct.frequency && acct.user_id){
    this.props.createTargetAcct(acct)
  }
}

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
  this.setState({ data: { ...data, [name]: value }, loading:true},()=>{
    this.fetchUsers()
  });
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  console.log(index)
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    if(data.success === false){
      this.setState({users: [], loading:false });
    }else{
      this.setState({users: data.data, all:data, loading:false });
    }
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

// handleChangeAcct(event) {
//   const { name, value } = event.target;
//   const { acct } = this.state;
//     this.setState({ acct: { ...acct, [name]: value }});
// }

handleChangeAcct = event => {
  const {acct} = this.state
  const {name, value, checked} = event.target
  if(name === "id"){
    this.setState({id:checked})
  }else if(name === "start_date"){
    var currentDate = new Date(value);
    let freq = Math.round(acct.targeted_amount / acct.amount); 
    if(acct.frequency === "Daily"){
        let raw = currentDate.setDate(currentDate.getDate()+freq);
        let end_date = new Date(raw);
        let mn = end_date.getMonth()+1;
        let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
        let month = mn <10 ? "0"+mn : mn;
        let date = end_date.getFullYear() +'-'+month+'-'+ day;
        this.setState({ acct: {...acct, end_date: date, start_date: value } })
    }else if(acct.frequency === "Weekly"){
        let weeks = freq * 7;
        let raw = currentDate.setDate(currentDate.getDate()+weeks);
        let end_date = new Date(raw);
        let mn = end_date.getMonth()+1;
        let day = end_date.getDate() < 10 ? "0"+ end_date.getDate():end_date.getDate();
        let month = mn < 10 ? "0"+mn : mn;
        let date = end_date.getFullYear() +'-'+month+'-'+ day;
        this.setState({ acct: {...acct, end_date: date, start_date: value } })
    }else{
        let raw = currentDate.setMonth(currentDate.getMonth()+freq);
        let end_date = new Date(raw);
        let mn = end_date.getMonth()+1;
        let day = end_date.getDate() < 10 ? "0"+ end_date.getDate():end_date.getDate();
        let month = mn < 10 ? "0"+mn : mn;
        let date = end_date.getFullYear() +'-'+month+'-'+ day;
        this.setState({ acct: {...acct, end_date: date, start_date: value } })
    }
  }else if(name === "frequency"){
    if(acct.start_date !== "" && acct.amount !== "" && acct.targeted_amount !== ""){
        var currentDate = new Date(acct.start_date);
        let freq = Math.round(acct.targeted_amount / acct.amount);
        if(value === "Daily"){
            let raw = currentDate.setDate(currentDate.getDate()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else if(value === "Weekly"){
            let weeks = freq * 7;
            let raw = currentDate.setDate(currentDate.getDate()+weeks);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else{
            let raw = currentDate.setMonth(currentDate.getMonth()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value } })
        }
    }else{
        this.setState({ acct: { ...acct, [name]: value} });
    }
  }else if(name === "amount"){
    if(acct.targeted_amount !== "" && acct.start_date !== "" && acct.frequency !== ""){
        var currentDate = new Date(acct.start_date);
        let freq = Math.round(acct.targeted_amount / value);
        if(acct.frequency === "Daily"){
            let raw = currentDate.setDate(currentDate.getDate()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else if(acct.frequency === "Weekly"){
            let weeks = freq * 7;
            let raw = currentDate.setDate(currentDate.getDate()+weeks);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else{
            let raw = currentDate.setMonth(currentDate.getMonth()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value } })
        }
    }else{
        this.setState({ acct: { ...acct, [name]: value,} });
    }
  }else if(name === "targeted_amount"){
    if(acct.amount !== "" && acct.start_date !== "" && acct.frequency !== ""){
        var currentDate = new Date(acct.start_date);
        let freq = Math.round(value / acct.amount);
        if(acct.frequency === "Daily"){
            let raw = currentDate.setDate(currentDate.getDate()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else if(acct.frequency === "Weekly"){
            let weeks = freq * 7;
            let raw = currentDate.setDate(currentDate.getDate()+weeks);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value} })
        }else{
            let raw = currentDate.setMonth(currentDate.getMonth()+freq);
            let end_date = new Date(raw);
            let mn = end_date.getMonth()+1;
            let day = end_date.getDate() < 10 ? "0"+ end_date.getDate() : end_date.getDate();
            let month = mn <10 ? "0"+mn : mn;
            let date = end_date.getFullYear() +'-'+month+'-'+ day;
            this.setState({ acct: {...acct, end_date: date, [name]:value } })
        }
    }else{
        this.setState({ acct: { ...acct, [name]: value,} });
    }
  }else{
    this.setState({acct:{...acct, [name]:value}})
  }
};

handleChangeUser = (event, values) =>{
  const {name, value } = event.target;
  const { acct, usersData } = this.state;
  this.fetchUserData({term: value});
  usersData.forEach(user => {
    if(values === user.id + user.first_name + " " + user.last_name){
      this.setState({ acct: { ...acct, user_id:user.id }});
    }
  });
}

tabbed = (id) => {
	this.setState({
	  users: id === 0? this.state.categories.filter((ne) =>ne.status == id) : this.state.categories.filter((ne) =>ne.status == id),
	  current_index: id
	})
};

render(){
  let obj = { array: [] };
    for (var l=0;l<31;l++){
        obj.array[l] = l+1;
    }
  const {theme} = this.props
  const {users, loading, show, acct, data, all, usersData, current_index} = this.state
    return (
      <div style={{padding: theme.spacing(3)}}>
		<Grid item lg={12} md={12} sm={12} xs={12}>
		  <Button size="small"
			variant={current_index === 1 ?"contained":"outlined"}
			color="secondary"
			onClick={() => this.tabbed(1)}>
			Ongoing
		  </Button>
		  <Button size="small"
			variant={current_index === 0 ?"contained":"outlined"}
			color="secondary"
			onClick={() => this.tabbed(0)}>
			Completed
		  </Button>
		</Grid>
        <div style={{height: '42px', marginTop: theme.spacing(1)}}>
          <TextField
            style={{width:"30%"}}
            select
            label="search"
            name="new_search"
            margin="dense"
            variant="outlined"
            value={data.new_search}
            onChange={this.handleChange}>
              <MenuItem value={""}>Select an option</MenuItem>
              <MenuItem value={"Daily"}>Daily</MenuItem>
              <MenuItem value={"Weekly"}> Weekly</MenuItem>
              <MenuItem value={"Monthly"}>Monthly</MenuItem>
              <MenuItem value={"Wallet"}> Wallet</MenuItem>
              <MenuItem value={"Bank Account"}> Bank Account </MenuItem>
              <MenuItem value={"auto save"}> Auto Save </MenuItem>
              <MenuItem value={"manual"}> Manual </MenuItem>
          </TextField>
         
          <div style={{float:'right'}}>
            <div className="row">
              <span className="spacer" />
              <Button color="primary" onClick={this.handleOpen} variant="contained">Create Target Plan</Button>
            </div>
          </div>
        </div>
        <div style={{marginTop: theme.spacing(2)}}>
          <UsersTable users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} link={"target"}/>
        </div>
        {/* Modal Add New Account start*/}
        <Dialog
            open={show}
            fullWidth={true}
            maxWidth = {'xs'}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Create Target Plan</DialogTitle>  
            <Divider />
            <DialogContent>
              <CardContent >
              <ValidatorForm autoComplete="off" noValidate onSubmit={this.handleSubmit}>
              <Grid>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  onChange={(event, value) => this.handleChangeUser(event, value)}
                  options={usersData.map((option) =>option.id + option.first_name + " " + option.last_name )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(event, value) => this.handleChangeUser(event, value)}
                      label="Search users"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
                  <TextValidator
                    fullWidth
                    placeholder="Target Name"
                    margin="dense"
                    name="target_name"
                    validators={[
                        "required"
                      ]}
                    value={acct.target_name}
                    variant="outlined"
                    type="text"
                    helperText="Please enter Target Name"
                    onChange={this.handleChangeAcct}
                  />
                  <TextValidator
                    fullWidth
                    placeholder="Targeted Amount"
                    margin="dense"
                    name="targeted_amount"
                    validators={[
                        "required"
                      ]}
                    value={acct.targeted_amount}
                    variant="outlined"
                    type="number"
                    helperText="Please enter user Targeted Amount"
                    onChange={this.handleChangeAcct}
                  />
                  <TextValidator
                    fullWidth
                    placeholder="Frequent Amount"
                    margin="dense"
                    name="amount"
                    validators={[
                        "required"
                      ]}
                    value={acct.amount}
                    variant="outlined"
                    type="number"
                    helperText="Please enter user Frequent Amount"
                    onChange={this.handleChangeAcct}
                  />
                  <TextValidator
                    fullWidth
                    placeholder="Frequency"
                    margin="dense"
                    name="frequency"
                    validators={[
                        "required"
                      ]}
                    value={acct.frequency}
                    select
                    variant="outlined"
                    helperText="Please choose frequency"
                    onChange={this.handleChangeAcct}>
                    <MenuItem value={"Daily"}>Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </TextValidator>
                  <TextValidator
                    fullWidth
                    placeholder="Transaction Time"
                    margin="dense"
                    name="transaction_time"
                    validators={[
                        "required"
                      ]}
                    value={acct.transaction_time}
                    variant="outlined"
                    type="time"
                    helperText="Please choose transaction time"
                    onChange={this.handleChangeAcct}
                  />
                  {acct.frequency !== "Daily"&&
				  <TextValidator
                    fullWidth
                    placeholder="Transaction Day"
                    margin="dense"
                    name="transaction_day"
                    value={acct.transaction_day}
                    variant="outlined"
                    select
                    helperText="Please choose Transaction Day"
                    onChange={this.handleChangeAcct}>
                    <MenuItem value={"1"}>Monday</MenuItem>
                    <MenuItem value={"2"}>Tuesday</MenuItem>
                    <MenuItem value={"3"}>Wednesday</MenuItem>
                    <MenuItem value={"4"}>Thursday</MenuItem>
                    <MenuItem value={"5"}>Friday</MenuItem>
                    <MenuItem value={"6"}>Saturday</MenuItem>
                    <MenuItem value={"7"}>Sunday</MenuItem>
                  </TextValidator>}
                  {acct.frequency === "Monthly"&&
				  <TextValidator
                    fullWidth
                    margin="dense"
                    select
                    label="Transaction day of the month"
                    value={acct.transaction_month}
                    name="transaction_month"
                    onChange={this.handleChangeAcct}
                    variant="outlined"
                    helperText="Please choose Transaction day of the month"
                  >
                    {obj.array.map((item) =>
                      <MenuItem value={item}key={item}>{item}</MenuItem>
                    )}
                  </TextValidator>}
                  <TextValidator
                    fullWidth
                    margin="dense"
                    name="start_date"
                    value={acct.start_date}
                    variant="outlined"
                    type="date"
                    helperText="Please choose start date"
                    onChange={this.handleChangeAcct}
                  />
                  <TextValidator
                    fullWidth
                    margin="dense"
                    name="end_date"
                    value={acct.end_date}
                    variant="outlined"
                    type="date"
                    helperText="Please choose End Date"
                    // onChange={this.handleChangeAcct}
                  />
                </Grid>
                {this.props.savings && <CircularProgress />}
                <Grid>
                  <Button type="submit" color="primary" variant="contained">
                    Submit 
                  </Button>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </DialogContent>
        </Dialog>
        {/* Modal Add New end Account */}
      </div>
    );
  };
}


  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  createTargetAcct: adminActions.createTargetAcct,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(TargetSavings))
);