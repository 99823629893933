import React, {Component } from 'react';
import { Button, Grid} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { authHeader, history } from 'redux/logic';
import ProductTable from './components/ProductTable';
import { getConfig, setLastUrl } from 'redux/config/config';
import { adminActions } from 'redux/action';


class OrderList extends Component{
  constructor(props){
    super(props)
    setLastUrl()
    this.state={
      data:{
        first_name : "",
        last_name : "",
        middle_name : "",
        email : "",
        phone_no : "",
        password : ""
    },
        users: [],
        category:[],
        loading:true,
        all: [],
        search: "",
        open:false,
        show:false,
        categories:[],
        current_index:0,
    }
    this.fetchOrders = this.fetchOrders.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);

  }

componentDidMount(){
  this.fetchOrders()
}

handleOpen = () =>{
  this.setState({show: true})
}

handleClose = () =>{
  this.setState({show: false})
}

handleSubmit =()=>{
  const {data} = this.state
  if(data.first_name && data.last_name && data.password && data.phone_no &&data.middle_name && data.email ){
    this.props.addUser(data)
  }
}

fetchOrders = () =>{
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
  fetch(getConfig('allOrders'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
	}
	if(data.success === false){
		this.setState({users: [], loading:false });
	}else{
		this.setState({users: data.data, all:data, category:data.data, loading:false});
	}
})
.catch(error => { 
    if (error === "Unauthorized") {
        this.props.logout();
       }
    this.setState({loading:false });
    console.error('There was an error!', error);
});
}

handleChange =(e)=>{
  e.preventDefault()
  const {name, value} = e.target
  const {data} = this.state
  this.setState({data:{...data, [name]:value }}) 
}
searchChange(event) {
  const { name, value } = event.target;
  const { search, users, all } = this.state;
  
  this.setState({ search: value, users: value === "" ? users : users.filter((q)=>
  q.last_name.toLowerCase().indexOf(value)  !== -1 
  || q.first_name.toLowerCase().indexOf(value)  !== -1 
  || q.email.toLowerCase().indexOf(value)  !== -1 )});
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

tabbed = (id) => {
  this.setState({
    users: id === 0? this.state.category : this.state.category.filter((ne) =>ne.order_status == id),
    current_index: id
  })
};

render(){
  const categories = [{name:"Pending", id:"1"}, {name:"Paid", id:"2"}, {name:"Approved", id:"3"}, {name:"Declined", id:"4"}, {name:"Delivered", id:"5"}, {name:"Closed", id:"6"}]
  let {theme} = this.props
  const {users, loading, search, current_index, show, data, all} = this.state
  return (
    <div style={{padding: theme.spacing(3)}}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button size="small"
				variant={current_index === 0 ?"contained":"outlined"}
				color="secondary"
				onClick={() => this.tabbed(0)}>
				All
			</Button>
            
              {categories.map((cat) => (
                <Button size="small" key={cat.id}
                  variant={current_index === cat.id ?"contained":"outlined"}
                  color="secondary"
                  onClick={() => this.tabbed(cat.id)}>
                    {cat.name}
                </Button>
              ))}
        </Grid>
      <div style={{marginTop: theme.spacing(2)}}>
        <ProductTable table={"order"} users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} />
      </div>
    </div>
    );
  };
}

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  addUser: adminActions.addUser,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(OrderList))
);