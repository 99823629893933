import React from "react";
import CustomCommissionTab from "./components/CustomCommissionTab";

export default function CommissionTab(props) {

  return (
    <div className="m-sm-10">
        <CustomCommissionTab />
      </div>
  );
}
