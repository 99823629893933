import React, { useState, Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, checkToken, numberFormat, setLastUrl } from '../../../redux/config/config'
import { authHeader, history } from '../../../redux/logic';
import { SearchInput } from 'components';
import { UsersTable } from '../components/Table';
// import { UsersToolbar } from '../components/Savings';
import { Grid, Card, Button, Typography, TextField,
  CardContent, DialogTitle, DialogContent,
   DialogActions, Divider, Dialog, MenuItem, CardActions, CircularProgress } from '@material-ui/core';
import {Link } from "react-router-dom";


class Admin extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    var currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let entry_date = currentDate.getFullYear() + "-" + month + "-" + day;
    this.state ={  
      data:{
        // invetment_type: '',
        name : "",
        email : "",
        password : "",
        role:""
      },
      search_term:{
        search_term:"",
      },
      users: [],
      all:[],
      investments:[],
      search: "",
      open:false,
      show:false,
      loading: true,
      roles:[]
    }
    
    this.fetchUsers = this.fetchUsers.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
    // this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    // this.onChange = this.onChange.bind(this);

  }

fetchUsers = () =>{
  const {search_term} = this.state
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body:JSON.stringify(search_term)
    };
    fetch(getConfig('getAdminShow'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
	}
	if(data.status === false){
		this.setState({users: [], all:[], loading:false });
	  }else{
		this.setState({users: data.data, all:data, loading:false });
	  }
})
.catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
       }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
  	const requestOpt = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json'}
	};
  fetch(getConfig('getAllRole'), requestOpt)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
	if(data.status === false){
		this.setState({roles: [], loading:false });
	}else{
		this.setState({roles: data, loading:false });
	}
  })
  .catch(error => {
	if (error === "Unauthorized") {
		this.props.logout()
	}
    this.setState({loading:false});
  });
}

componentDidMount() {
  this.fetchUsers();
}

searchChange(event) {
  const { value } = event.target;
  const { search_term } = this.state;
  this.setState({ search_term: { ...search_term, search_term: value }, loading:true},()=>{
    this.fetchUsers()
  });
}

handleOpen= () =>{
    this.setState({open:true})
 }

handleClose= () =>{
  this.setState({open:false})
 }

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
  this.setState({
      data: {
          ...data,
          [name]: value
      }
  });
}

handleSubmit(event) {
  event.preventDefault();
  const { data } = this.state;
  console.log(data);
    if ( data.name && data.email && data.password) {
      this.props.addAdmin(data);
    }
}

fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, users:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}


render(){
  const {theme, savings} = this.props
  const {users, loading, data, search, open, all, roles} = this.state
    return (
      <div style={{padding: theme.spacing(3)}}>
        <div style={{height: '42px',alignItems: 'center', marginTop: theme.spacing(1)}}>
          <SearchInput
            value={search}
            onChange={this.searchChange}
            style={{marginRight: theme.spacing(1), width:300, float:'left'}}
            placeholder="Search user" />
         
          <div style={{float:'right'}}>
            <Grid container alignItems="center">
              <Button
                color="primary"
                variant="contained"
                onClick={()=>this.handleOpen()}>
                Add Admin
              </Button>
            </Grid>
          </div>
        </div>
        <div style={{marginTop: theme.spacing(2)}}>
          <UsersTable users={users} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading}/>
        </div>
        
      {/* Modal */}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">Add Admin</DialogTitle>
        <Divider />     
        <DialogContent>
          <CardContent className="content">
            <form  noValidate autoComplete="off" onSubmit={this.handleSubmit}>
              <TextField
                fullWidth
                label="Admin Name"
                placeholder="Admin Name"
                margin="dense"
                name="name"
                onChange={this.handleChange}
                required
                value={data.name}
                variant="outlined"/>
              <TextField
                fullWidth
                label="Email"
                placeholder="Email"
                margin="dense"
                name="email"
                onChange={this.handleChange}
                required
                value={data.email}
                variant="outlined"/>
              <TextField
                fullWidth
                label="Password"
                placeholder="Password"
                margin="dense"
                type="password"
                name="password"
                onChange={this.handleChange}
                required
                value={data.password}
                variant="outlined"/>
              
              <TextField
                fullWidth
                label="Admin Role"
                placeholder="Select role"
                margin="dense"
                select
                name="role"
                onChange={this.handleChange}
                required
                value={data.role}
                variant="outlined">
                  {roles.map(r =>(<MenuItem key={r.id} value={r.id}>{r.role}</MenuItem>))}
              </TextField>
            </form>
          </CardContent>
          <Divider /> 
          <DialogActions>
            {savings && <CircularProgress /> }
            <Button
            type="submit"
              variant="contained"
              color="primary"
              style={{marginLeft:8}}
              onClick={this.handleSubmit}>
              Submit
            </Button>
            <Button onClick={this.handleClose} 
              variant="contained"
              style={{color:'white', marginRight:8, backgroundColor:'red'}}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Modal */}
      </div>
    );
  };
}
  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  saveWallet: adminActions.saveWallet,
  logout: adminActions.logout,
  addAdmin: adminActions.addAdmin,
  // adminUpdateMarketCategory: adminActions.adminUpdateMarketCategory,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(Admin))
);