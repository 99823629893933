/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
const role = JSON.parse(localStorage.getItem("admin"))
// const ispermmited = role&& role.permissions && !role.permissions.includes(page.role)
const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    color:'#fff'
  },
  button: {
    // color: colors.blueGrey[800],
    color:'#000',
    padding: '12px 10px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontSize:16,
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    // color: theme.palette.icon,
    color:'#000',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: "#fff",
    background: "#03A9F4",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: "#fff"
      
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List className={clsx(classes.root, className)} >
      {pages.map(page =>{
        if (page.role && (role&& role.permissions && !role.permissions.includes(page.role))) {
          return null
        }
        
        return (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            // style={{color:'#fff'}}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      )})}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
