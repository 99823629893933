import React, { Component } from "react";
import { adminActions } from "../../redux/action";
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig} from '../../redux/config/config'
import { authHeader} from '../../redux/logic';
import { Notifications, Password, UsersTable, Setting } from './components';
import {
  DialogActions,
  DialogContent,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { SearchInput } from "components";
import BanksTable from "./components/UsersTable/BanksTable";
import { RolesTable } from "./components/RolesTable";


const useStyles = makeStyles(theme => ({
  root: {
    padding: 5
  }
}));

class Settings extends Component {
  constructor(props){
    super(props)
    this.state ={  
      data:{
        commission : "",
        package_name : ""
      },
      open:false,
      users: [],
      banks:[],
      roles:[],
      all: [],
      codes:[],
      accounting:[],
      search: "",
      show:false,
      loading: true,
    }  

    this.fetchUsers = this.fetchUsers.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }  

  componentDidMount(){
    this.fetchUsers();
  }

fetchUsers = () =>{
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    fetch(getConfig('adminShowAllCommission'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    if(data.success === false){
      this.setState({users: []});
    }else{
      this.setState({users: data});
    }
  })
  .catch(error => {
    if (error === "Unauthorized") {
          this.props.logout()
      }
  });

  
}

handleChange(event) {
  const { name, value } = event.target;
  const { data } = this.state;
  
      this.setState({
          data: {
              ...data,
              [name]: value
          }
      });
  
}

handleClose= () =>{
  this.setState({open:false})
}



handleSubmit(event) {
event.preventDefault();
const { data } = this.state;
  console.log(data);
  if ( data.commission && data.package_name) {
    this.props.addTargetCommission(data);
  }
} 

render(){
  const {theme, savings} = this.props
  const {search,data,users,open, banks, codes, accounting, roles} = this.state
    return (
      <div style={{padding: 10}}>
        <Grid container spacing={4} >
          <Grid item md={7} xs={12}>
            <Notifications add={false}/>
          </Grid>
          <Grid item md={5} xs={12}>
            <Password />
          </Grid>
          <Grid item md={12} xs={12} >
            <div style={{alignItems: 'center',marginTop: 5}}>
              <SearchInput
                value={search}
                onChange={this.searchChange}
                style={{marginRight: 5, width:300, float:'left'}}
                placeholder="Search Package Name"
              />
              <div style={{float:'right'}}>
                <div className="row">
                  <span className="spacer" />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={()=>this.handleOpen()}>
                    Add Commission
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <UsersTable users={users}/>
          </Grid>
          <Grid item md={6} xs={12}>
            <Setting codes={codes} accounting={accounting}/>
          </Grid>
          <Grid item md={6} xs={12}>
            <BanksTable banks={banks}/>
            {/* <Divider style={{marginTop:10}}/>
            <RolesTable roles={roles} /> */}
          </Grid>
        </Grid>
      
    </div>
  );
  };
}

function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  const actionCreators = {
    saveWallet: adminActions.saveWallet,
    logout: adminActions.logout,
    // adminChangePassword: adminActions.adminChangePassword,
    addTargetCommission: adminActions.addTargetCommission,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,  actionCreators)(Settings))
  );