import React, { Component } from 'react';
import { CircularProgress, Grid, Card } from '@material-ui/core';
import { withRouter, Link } from "react-router-dom";
import { adminActions } from "../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, numberFormat } from '../../redux/config/config'
import { authHeader } from '../../redux/logic';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MoneyIcon from '@material-ui/icons/Money';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import {
  Budget,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders,
  LoanList,
} from './components';
import { SearchInput } from 'components';
// import WalletBalance from './components/WalletBalance';
// import { TransactionTable } from 'views/pages/components/Table';
// import TotalInvestment from './components/TotalRegular';

class Dashboard extends Component{
  constructor(props){
    super(props)
    this.state={
        search_term:{
          search_term:"",
        },
        transactions:[],
        dashboardLoans:[],
        savingsData:[],
        histogramSavings:"",
        histogramLoans:"",
        histogramProducts:"",
        histogramShare:"",
        histogramBusiness:"",
        loans:[],
        count_users: 0,
        savings_balance: 0,
        save_loans:0,
        target_balance:0,
        market_balance:0,
        halal_balance:0,
        wallet_balance:0,
        loan_disbursed:0,
        loading:true,
        all: [],
        search: "",
        open:false,
        loading:true
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchTransac = this.fetchTransac.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.fetchLoan = this.fetchLoan.bind(this);
    this.fetchLoanApproved = this.fetchLoanApproved.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
    
  }

componentDidMount(){
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
};
  this.fetchUsers(requestOptions);
  this.fetchLoanApproved()
  this.fetchTransac();
  this.fetchLoan();
}

fetchUsers = (requestOptions) =>{
  fetch(getConfig('getAllDashboard'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    this.setState({count_users: data[0], savings_balance:data[1], save_loans:data[2],
       target_balance:data[3],market_balance:data[4],halal_balance:data[5], wallet_balance:data[6]});
})
.catch(error => {
    if (error === "Unauthorized") {
        this.props.logout();
       }
    this.setState({loading:false });
});

fetch(getConfig("loanDisbursed"), requestOptions)
.then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        this.setState({loading:false})
        return Promise.reject(error);
    }
    if(data.success == false){
      this.setState({loan_disbursed: 0, loading:false})
    }else{
      this.setState({loan_disbursed: data, loading:false})
    }
})
.catch(error => {
    if (error === "Unauthorized") {
        this.props.timeOut()
      }
      this.setState({loading:false});
    
});

fetch(getConfig('histogramData'), requestOptions)
    .then(async response => {
    const dat = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (dat && dat.message) || response.statusText;
        return Promise.reject(error);
    }console.log(dat)
    this.setState({
      histogramSavings: Object.values(dat.month.savings), 
      histogramLoans: Object.values(dat.month.loan), 
      histogramProducts: Object.values(dat.month.product), 
      histogramBusiness: Object.values(dat.month.business),
      histogramShare: Object.values(dat.month.share),
    })
  })
.catch(error => {
    if (error === "Unauthorized") {
        this.props.logout();
       }
    this.setState({loading:false });
});

fetch(getConfig('savingsData'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        this.setState({loading:false });
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    this.setState({savingsData: data});
})
.catch(error => {
    if (error === "Unauthorized") {
        this.props.logout();
       }
    this.setState({loading:false });
});

}

fetchLoanApproved = ()=>{
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body:JSON.stringify({serch_term:""})
	};
  fetch(getConfig('getAllApprovedLoan'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  //console.log(data)
  if (data.success === false){
    this.setState({loans:[]});
  }else{
    this.setState({loans:data.data});
  }

})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false});
});
}
fetchTransac = () =>{
  // const {search_term} = this.state
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        // body:JSON.stringify(search_term)
  };
  fetch(getConfig('transactions'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }     
  if(data.status === false){
		this.setState({transactions: [], loading:false });
	  }else{
    this.setState({transactions: data.data, loading:false});
	  }
})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false});
  console.error('There was an error!', error);
});
}
searchChange(event) {
  const { value } = event.target;
  const { search_term } = this.state;
  this.setState({ search_term: { ...search_term, search_term: value }, loading:true},()=>{
    this.fetchLoan()
  });
}
fetch_next_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.next_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, dashboardLoans:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_prev_page = ()=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, dashboardLoans:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

fetch_page = (index)=>{
  const {all} = this.state
  this.setState({ loading: true});
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    this.setState({ loading: false, dashboardLoans:data.data, all:data });
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}
fetchLoan = () =>{
  // const {search_term} = this.state
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        // body:JSON.stringify(search_term)
  };
  fetch(getConfig('dashboardLoans'), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }     
  if(data.status === false){
		this.setState({dashboardLoans: [], all:[], loading:false });
	  }else{
    this.setState({dashboardLoans: data.data,  all:data, loading:false});
	  }
})
.catch(error => {
  if (error === "Unauthorized") {
        this.props.logout()
     }
  this.setState({loading:false});
  console.error('There was an error!', error);
});
}
render(){
  const {theme} = this.props
  const {count_users,loan_disbursed, savings_balance,dashboardLoans, search, all, loading, transactions, histogramSavings, histogramLoans, histogramProducts, histogramShare, histogramBusiness, loans, save_loans,target_balance,wallet_balance, savingsData} = this.state
  return (
    <div style={{padding: theme.spacing(4)}}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/users"><Budget colors={"#4fa647"} icons={<SupervisorAccountIcon />} name={"Total Active Users"} count_users={count_users}/></Link>
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/savings_tab"><Budget colors={"#59153f"} icons={<MoneyIcon />} name={"Total Regular Savings"} count_users={numberFormat(savings_balance)}/></Link>          
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/targetsavings_tab"><Budget colors={"#a64782"} icons={<MoneyIcon />} name={"Total Target Savings"} count_users={numberFormat(target_balance)}/></Link>
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/shareholding_tab"><Budget colors={"#22591d"} icons={<MoneyIcon />} name={"Total ShareHoldings"} count_users={numberFormat(save_loans)}/></Link>
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/loan"><Budget colors={"#22591d"} icons={<MoneyIcon />} name={"Loans"} count_users={numberFormat(loan_disbursed)}/></Link>
        </Grid>
        <Grid
          item
          lg={4}
          sm={4}
          xl={4}
          xs={12}
          className="anim"
        >
          <Link to="/wallet"><Budget colors={"#22591d"} icons={<MoneyIcon />} name={"Total Wallet"} count_users={numberFormat(wallet_balance)}/></Link>
        </Grid>
         
         <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestSales 
            savings={histogramSavings} 
            loans={histogramLoans} 
            share={histogramShare} 
            products={histogramProducts} 
            business={histogramBusiness} />
        </Grid>
        {savingsData.length === 0 ?
        <CircularProgress />:
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <UsersByDevice savingsData={savingsData}/>
        </Grid>}
        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
            <LoanList users={dashboardLoans} pagination={all}
              fetch_page={this.fetch_page} 
              fetch_next_page={this.fetch_next_page} 
              fetch_prev_page={this.fetch_prev_page}
              loading={loading}
            />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <LatestOrders users={transactions} loading={loading}/>
        </Grid>
      </Grid>
    </div>
  );
};
}
  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  saveWallet: adminActions.saveWallet,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(Dashboard))
);
