import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Component } from "react";
import { getConfig } from "redux/config/config";
import { authHeader } from "redux/logic";
import { adminActions } from "redux/action";
import ProductTable from './components/ProductTable';
import { TextField } from "@material-ui/core";

class CompletedList extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: [],
      loading:true,
      all: [],
      search: "",
      open:false,
      show:false
    }
    this.fetchOngoingBusiness = this.fetchOngoingBusiness.bind(this);
    this.fetch_next_page = this.fetch_next_page.bind(this);
    this.fetch_page = this.fetch_page.bind(this);
    this.fetch_prev_page = this.fetch_prev_page.bind(this);
    this.searchChange = this.searchChange.bind(this);
  }

  componentDidMount(){
    this.fetchOngoingBusiness()
  }

  fetchOngoingBusiness = () =>{
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body:JSON.stringify({search_term:this.state.search})
    };
    fetch(getConfig('completedBusiness'), requestOptions)
    .then(async response => {
    const data = await response.json();
    if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
      console.log(data)
      if(data.success === false){
        this.setState({data: [], loading:false});
      }else{
        this.setState({data: data.data, all:data, loading:false});
      }
    })
    .catch(error => {
    if (error === "Unauthorized") {
      this.props.logout()
    }
  });
}

  handleChange =(e)=>{
    e.preventDefault()
    const {name, value} = e.target
    const {data} = this.state
    this.setState({data:{...data, [name]:value }}) 
  }
  
  fetch_next_page = ()=>{
    const {all} = this.state
    this.setState({ loading: true});
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    fetch(all.next_page_url, requestOptions).then(async (response) =>{
      const data =await response.json();
      this.setState({ loading: false, users:data.data, all:data });
    }).catch(error=>{
      if (error === "Unauthorized") {
        this.props.logout();
      }
    })
  }
  
  fetch_prev_page = ()=>{
    const {all} = this.state
    this.setState({ loading: true});
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    fetch(all.prev_page_url, requestOptions).then(async (response) =>{
      const data =await response.json();
      this.setState({ loading: false, users:data.data, all:data });
    }).catch(error=>{
      if (error === "Unauthorized") {
        this.props.logout();
      }
    })
  }
  
  fetch_page = (index)=>{
    const {all} = this.state
    this.setState({ loading: true});
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    fetch(all.path+"?page="+index, requestOptions).then(async (response) =>{
      const data =await response.json();
      this.setState({ loading: false, users:data.data, all:data });
    }).catch(error=>{
      if (error === "Unauthorized") {
        this.props.logout();
      }
    })
  }

  searchChange(event) {
    const { value } = event.target;
    this.setState({  search: value, loading:true},()=>{ this.fetchOngoingBusiness() });
  }

render(){
  let {theme} = this.props
  const {data, loading, all, search} = this.state
    return (
      <div style={{padding: theme.spacing(3)}}>
        <div style={{height: '42px' ,alignItems: 'center', marginTop: theme.spacing(1)}}>
          <TextField
            style={{width:"50%"}}
            label="search username"
            margin="dense"
            variant="outlined"
            value={search}
            onChange={this.searchChange}
            style={{marginRight: theme.spacing(1)}}
            placeholder="Search"
          />
        </div>
        <div style={{marginTop: theme.spacing(2)}}>
          <ProductTable table={"completed"} data={data} pagination={all} fetch_page={this.fetch_page} fetch_next_page={this.fetch_next_page} fetch_prev_page={this.fetch_prev_page} loading={loading} />
        </div>
      </div>
    );
  }
}

const actionCreators = {
  logout: adminActions.logout,
  createOrder: adminActions.createOrder,
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(CompletedList))
);