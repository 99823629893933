import React, { Component } from 'react'
import SimpleTabs from '../components/Tabs/RegularTabsPanel'

class SavingsTab extends Component {
    render() {
        return (
            <div>
                <SimpleTabs />
            </div>
        )
    }
}
  
  export default SavingsTab;