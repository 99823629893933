import React, { Component } from 'react'
import { SearchInput } from 'components'
import { adminActions } from 'redux/action';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
    DialogActions,
    DialogContent,
    CardContent,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    Button,
    TextField,
    CircularProgress,
  } from '@material-ui/core';
import { getConfig } from 'redux/config/config';
import { authHeader } from 'redux/logic';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DuesTable from './UsersTable/DuesTable';
import PayOptionTable from './UsersTable/PayOptionTable';

class Payment extends Component {

    constructor(props){
        super(props)
        this.state ={  
          data:{
            request_type  : '1',
            name : ""
          },
          open:false,
          users: [],
          all: [],
          search: "",
          loading: true,
        }  
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }  
    
componentDidMount(){
}
    
handleChange(event) {
    const { name, value } = event.target;
    const { data } = this.state;

    this.setState({
        data: {
            ...data,
            [name]: value
        }
    });
}
    
handleClose= () =>{
    this.setState({open:false})
}

handleSubmit(event) {
    event.preventDefault();
    const { data } = this.state;
    console.log(data);
    if ( data.name && data.request_type ) {
        this.props.AddPaymentOptions(data);
    }
} 

handleOpen= () =>{
    this.setState({open:true})
}
      
    render() {
        const {users, open, search, data, loading} = this.state
        return (
            <div style={{padding: 20}}>
                <div style={{height: '42px',alignItems: 'center', marginTop: 10}}>
                    <div style={{float:'right'}}>
                        <div className="row" style={{marginRight:2}}>
                            <span className="spacer" />
                            <Button
                            color="primary"
                            variant="contained"
                            onClick={()=>this.handleOpen()}>
                            Add Payment Option
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <PayOptionTable />
                </div>

                {/* Modal */}          
                < Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth = {'xs'}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle  id="alert-dialog-slide-title">Add Payment Option</DialogTitle>  
                    <Divider />     
                    <DialogContent>
                    <CardContent className="content">
                    <form  noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                        <Grid >
                            <Grid>
                                <label> Payment Option Name</label>
                                <OutlinedInput
                                    fullWidth
                                    placeholder=" Payment Option Name"
                                    name="name"
                                    type="text"
                                    margin="dense"
                                    value={data.name} 
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>                 
                        </Grid>
                    </form>
                    </CardContent>             
                    {/* </DialogContentText> */}
                    <Divider /> 
                    <DialogActions>
                    <Grid item md={10} xs={10}>
                    {this.props.savings && <CircularProgress />}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{marginLeft:8}}
                        onClick={this.handleSubmit}>
                        Add Due
                    </Button>
                    </Grid> 
                    <Button onClick={this.handleClose} 
                        variant="contained"
                        style={{color:'white', marginRight:8, backgroundColor:'red'}}>
                        Cancel
                    </Button>
                    </DialogActions>
                    </DialogContent>
                </Dialog>
                {/* Modal */}
            </div>
        )
    }
}
function mapState(state) {
    const { savings } = state.savings;
    return { savings };
  }
  
  const actionCreators = {
    logout: adminActions.logout,
    AddPaymentOptions: adminActions.AddPaymentOptions,
  };
  
  export default withStyles({}, { withTheme: true })(
    withRouter(connect(mapState,actionCreators)(Payment))
  );
