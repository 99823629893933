import React, { Component } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter, Link } from "react-router-dom";
import { adminActions } from "../../../redux/action";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { getConfig, numberFormat, setLastUrl } from '../../../redux/config/config'
import { authHeader } from '../../../redux/logic';
import { Button, Grid, CardContent, Divider, CardActions, Typography, DialogContent, DialogTitle, Dialog, CircularProgress } from '@material-ui/core';
import UserAccount from './compnent/UserAccount/UserAccount';
import swal from 'sweetalert'
import UserProfile from './compnent/UserAccount/UserProfile';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class UserDetails extends Component {
  constructor(props){
    super(props)
    setLastUrl()
    const id = this.props.match.params.id;
    this.state ={
      users: {},
      data:{email:"", password:"", id:id, activate_status:"", activate_record:'', email_status:""},
      bank: [],
      all: [],
      bala:[],
      loan:[],
      balax:[],
      loading: true,
      search: "",
      user_status:'',
      open:false,
      show:false,
      id
    }
    this.searchChange = this.searchChange.bind(this);
    this.handleEnable = this.handleEnable.bind(this);
    this.handleDeclaine = this.handleDeclaine.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }   

componentDidMount(){
  let user = JSON.parse(localStorage.getItem('admin'));
  const {data} = this.state
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig('getSingleUserDetails') + this.state.id + `?token=`+user.token, requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }  
  if(data[0].length === 0){
    this.setState({users: []});
  }else{
    this.setState({users: data[0][0]});
  }
  if(data[1].length === 0){
    this.setState({bank: [], loading:false });
  }else{
    this.setState({bank: data[1][0], bala: data[2][0], data: {...data, email_status: data.email_status}, loading:false });
  }
  if(data[2].length === 0){
    this.setState({users: []});
  }else{
    this.setState({bala: data[2].share_holdings, balax:data[2].regular_savings});
  }
  if(data[2].loan.length === 0){
    this.setState({loan: []});
  }else{
    this.setState({loan: data[2].loan[0]});
  }})
  .catch(error => {
    if (error === "Unauthorized") {
        this.props.logout()
      }
    this.setState({loading:false, err : "internet error" });
    console.error('There was an error!', error);
  });
}

handleOpen = () =>{
  const {data, users} = this.state 
  if(users.online_banking==0){
    this.setState({show: true, data:{...data, email:users.email, activate_status:1 }})
  }else{
    this.setState({open: true, data:{...data, email:users.email, activate_status:0 }})
  }
}

handleClose = () =>{
  this.setState({show: false, open:false})
}

handleSubmit =()=>{
  const {data} = this.state
  if(data.email &&data.password &&data.email_status){
    this.props.activateOnline(data)
  }else{
    this.props.activateOnline(data)
  }
}

handleChange =(e)=>{
  e.preventDefault()
  const id = this.props.match.params.id
  const {name, value} = e.target
  const {data} = this.state
  if(name == "activate_record"){
    const {checked} = e.target
    this.setState({data:{...data, activate_record:checked?1:0}})
  }else{
    this.setState({data:{...data, [name]:value}})
  }
}

handleDeclaine = () => {
  const id = this.props.match.params.id;
  swal({
    title: "Are you sure?",
    text: "Do you to Disable this User?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
  if (willDelete) {
    this.props.disableUsers(id);
    swal("Loading...",{   
      buttons:false
    });
  }
});
}

handleEnable = () => {
  const id = this.props.match.params.id;
  swal({
    title: "Are you sure?",
    text: "Do you to Enable this User?",
    icon: "success",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
  if (willDelete) {
    this.props.enableUsers(id);
    swal("Loading...",{   
      buttons:false
    });
  }
});
}

searchChange(event) {
  const { name, value } = event.target;
  const { search, users, all } = this.state;
  
  this.setState({ search: value, users: value === "" ? all : all.filter((q)=>
  q.last_name.toLowerCase().indexOf(value.toLowerCase())  !== -1 
  || q.first_name.toLowerCase().indexOf(value.toLowerCase())  !== -1 
  || q.frequency.toLowerCase().indexOf(value.toLowerCase())  !== -1 )});
}

render(){
  const {theme, activateUser} = this.props
  const {users, bala,balax, loan, bank, loading, search, open, show, id, data} = this.state
  console.log(loan)
    return (
      <div style={{padding: theme.spacing(3)}}>
    
    {loading?
      <div style={{marginTop:150, display:"flex", alignItems:"center", flexDirection:"column", justifyItems:"center"}}>
      <CircularProgress />
      </div>:    
        <div style={{marginTop: theme.spacing(2)}}>
        <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <UserAccount users={users} loading={loading} activateUser={()=>activateUser(id)}/>
              <Grid style={{display:'flex'}}>
                <CardActions>
                  <Link to="/users">
                    <Button
                      color="secondary"
                      variant="contained"
                    >
                      Back
                    </Button> 
                  </Link>
                </CardActions>
                <CardActions>
                  {users.user_status === 1 ?
                  <Button
                    style={{background:'red', color:'white'}}
                    variant="contained"
                    onClick={()=>this.handleDeclaine()}>Disable
                  </Button> :
                  <Button
                    style={{color:'white',background:'blue'}}
                    variant="contained"
                    onClick={()=>this.handleEnable()}>Enable
                  </Button>}
                  <Button
                    style={{color:'white',background:'green'}}
                    variant="contained"
                    onClick={()=>this.handleOpen()}>{users.online_banking==0? "Online":"Offline"}
                  </Button>
                </CardActions>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="py-3" />
                  <Typography variant="h6">Savings Account</Typography>
                  <Link to={`/regulardetails/${id}`}><Button style={{width:"100%"}} variant="outlined">Regular Savings:   {numberFormat(balax)}</Button></Link>
                  <Link to={`/savetoloan_details/${id}`}><Button style={{width:"100%"}} variant="outlined">Share Holdings:    {numberFormat(bala)}</Button></Link>
                  <Typography variant="h6">Loans</Typography>
                  {loan.length == 0 ?
                    <Button style={{width:"100%"}} variant="outlined">
                      Loans <br/>Loan Amount: {numberFormat(0)} <br/> Repaid Loan: {numberFormat(0)}
                    </Button>:
                    <Link to={`/loandetails/${loan.id}`}>
                      <Button style={{width:"100%"}} variant="outlined">
                        Loans <br/>Loan Amount: {numberFormat(loan.loan_amount)} <br/> Repaid Loan: {numberFormat(loan.loan_balance)}
                      </Button>
                    </Link>
                  }
              </Grid>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={8}
              xs={12}
            >
              <UserProfile users={users} bank={bank} loading={loading}/>
            </Grid>
            </Grid>
          </div>
      }
      {/* Modal Add New start*/}
      <Dialog
        open={show}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Activate Online Banking</DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent >
          <ValidatorForm autoComplete="off" noValidate onSubmit={this.handleSubmit}>
          <Grid>
              {users.email_status === 1?
                <TextValidator
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                validators={[
                    "required"
                  ]}
                value={data.email}
                variant="outlined"
                type="email"
                readOnly
              />:
                <TextValidator
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                validators={[
                    "required"
                  ]}
                value={data.email}
                variant="outlined"
                type="email"
                helperText="Please enter user Email"
                onChange={this.handleChange}
              />
              }
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.activate_record === 1? true:false}
                    onChange={this.handleChange}
                    name="activate_record"
                    color="primary"
                  />
                }
                label="Activate Online Banking"
              />
              {/* <Checkbox
                  name="activate_record"
                  checked={data.activate_record}
                  onChange={this.handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
              /><Typography variant="caption">Would you like activate this User</Typography> */}
              {data.activate_record === 1 &&
                <TextValidator
                fullWidth
                placeholder="Password"
                margin="dense"
                name="password"
                validators={[
                    "required"
                  ]}
                value={data.password}
                variant="outlined"
                type="password"
                helperText="Please enter user password"
                onChange={this.handleChange}
              />}
            </Grid>
            {this.props.savings && <CircularProgress />}
            <Grid>
              <Button type="submit" color="primary" variant="contained">
                Submit 
              </Button>
            </Grid>
            </ValidatorForm>
          </CardContent>
        </DialogContent>
      </Dialog>
      {/* Modal Add New end */}

      {/* Modal Add New start*/}
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth = {'xs'}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Activate Offline Banking</DialogTitle>  
        <Divider />
        <DialogContent>
          <CardContent >
          {users.email_status === 0?
            <ValidatorForm autoComplete="off" noValidate onSubmit={this.handleSubmit}>
            <Grid>
                <TextValidator
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                validators={[
                    "required"
                  ]}
                value={data.email}
                variant="outlined"
                type="email"
                helperText="Please enter user Email"
                onChange={this.handleChange}
              />
              {data.activate_record === 1 &&
                <TextValidator
                fullWidth
                placeholder="Password"
                margin="dense"
                name="password"
                validators={[
                    "required"
                  ]}
                value={data.password}
                variant="outlined"
                type="password"
                helperText="Please enter user password"
                onChange={this.handleChange}
              />}
            </Grid>
            {this.props.savings && <CircularProgress />}
            <Grid>
              <Button type="submit" color="primary" variant="contained">
                Update Email 
              </Button>
            </Grid>
          </ValidatorForm>:          
          <ValidatorForm autoComplete="off" noValidate onSubmit={this.handleSubmit}>
          <Grid>
              <TextValidator
                fullWidth
                placeholder="Email"
                margin="dense"
                name="email"
                validators={[
                    "required"
                  ]}
                value={data.email}
                variant="outlined"
                type="email"
                helperText="Please enter user Email"
              />
            </Grid>
            {this.props.savings && <CircularProgress />}
            <Grid>
              <Button type="submit" color="primary" variant="contained">
                Submit 
              </Button>
            </Grid>
            </ValidatorForm>
          }
          </CardContent>
        </DialogContent>
      </Dialog>
      {/* Modal Add New end */}
      
      </div>
    );
  };
}
  
function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  disableUsers: adminActions.disableUsers,
  enableUsers: adminActions.enableUsers,
  activateOnline: adminActions.activateOnline,
  activateUser: adminActions.activateUser,
  logout: adminActions.logout,
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(UserDetails))
);
