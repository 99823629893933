import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Grid, Button, CardContent, DialogContent, Divider, DialogTitle, Dialog, MenuItem, CircularProgress, TextField } from '@material-ui/core';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ProductsToolbar, ProductCard } from './components';
import { authHeader } from 'redux/logic';
import { getConfig } from 'redux/config/config';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { adminActions } from 'redux/action';
import swal from 'sweetalert';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Paginate from 'views/pages/components/Users/UsersTable/paginate';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const ProductList = (props) => {
  const classes = useStyles();
  // const [products] = useState(mockData);
  const [product, setProduct] = useState([])
  const [pagination, setPagination] = useState([])
  const [open, setOpen] = useState(false)
  const [downPayment, setDownPayment] = useState()

  const [data, setData] = useState(
    {category_id:"", product_name:"", short_description:"", description:"", 
    down_payment:"", 
    mark_up:"", 
    total_product:"",
    total:"",
    price:"", quantity:"", image:null})

  const [category, setcategory] = useState([]);
  const uploadedImage = React.createRef();
  const imageUploader = React.createRef();
  
  useEffect(() => {
    fetchProduct("")
    fetchProductCategory()
  },[])

  const fetchProduct =(search_term)=>{
    const requestOpt = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({search_term})
    };
    fetch(getConfig('allProduct'), requestOpt)
      .then(async response => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      setProduct(data.products.data)
      setPagination(data.products)
    })
    .catch(error => {
      if (error === "Unauthorized") {
        props.logout();
      }
    });
  }

const fetchProductCategory = (value) =>{
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  fetch(getConfig("getProductCategoryDropdown"), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }console.log(data)
  setcategory(data)
  })
  .catch(error => {
    console.log(error)
  });
  fetch(getConfig("getDownPayment"), requestOptions)
  .then(async response => {
  const data = await response.json();
  if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
  }
  setDownPayment(data)
  })
  .catch(error => {
    console.log(error)
  });
}

const handleSubmit = (event)=>{
  event.preventDefault();
  let fd = new FormData();
  fd.append('description', data.description);
  fd.append('short_description', data.short_description);
  fd.append('category_id', data.category_id);
  fd.append('product_name', data.product_name);
  fd.append('price', data.price);
  fd.append('mark_up', data.mark_up);
  fd.append('down_payment', data.down_payment);
  fd.append('total_product', data.total_product);
  fd.append('total', data.total);
  fd.append('quantity', data.quantity);
  fd.append('image', data.image);
  if(data.image !== null){
    props.addProduct(fd);
  }else{
    swal("please add image to upload")
  }
  
}

const handleChange = (event) =>{
  const { name, value } = event.target;
  event.persist();
  if(name === "category_id"){
    category.forEach(cat => {
      if(value === cat.category_name){
        setData(data=>({ ...data, [name]:cat.id}))
      }
    });
  }else if(name === "price"){
    const amt = (downPayment / 100) * value
    setData(data=>({ ...data, [name]:value, total_product: value - amt, down_payment:downPayment, mark_up:0, total:0}))
  }else if(name === "mark_up"){
    const amt = (Number(value) / 100) * Number(data.total_product)
    setData(data=>({ ...data, [name]:value, total:amt + Number(data.total_product)}))
  }else{
    setData(data=>({ ...data, [name]:value}))
  }
}

const handleProfileImage=(e)=>{
  const [file, name] = e.target.files;
  if(file){
      setData(data=>({ ...data, image: e.target.files[0]}))
  }
}

const fetch_next_page = ()=>{
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(pagination.next_page_url, requestOptions).then(async (response) =>{
    const data = await response.json();
    setProduct(data.products.data)
    setPagination(data.products)

  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

const fetch_prev_page = ()=>{
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(pagination.prev_page_url, requestOptions).then(async (response) =>{
    const data =await response.json();
    setProduct(data.products.data)
    setPagination(data.products)
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

const fetch_page = (index)=>{
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  fetch(pagination.path+"?page="+index, requestOptions).then(async (response) =>{
    const data =await response.json();
    setProduct(data.products.data)
    setPagination(data.products)
  }).catch(error=>{
    if (error === "Unauthorized") {
      this.props.logout();
    }
  })
}

const handleClose = ()=>{
  setOpen(false)
}

const handleOpen = ()=>{
  setOpen(true)
}

const searchChange = (event) =>{
  const { value } = event.target;
  // setloading(true)
  fetchProduct(value)
}

  return (
    <div className={classes.root}>
      <ProductsToolbar handleOpen={handleOpen} handleSearch={searchChange}/>
      <div className={classes.content}>
        <Grid
          container
          spacing={3}>
          {product.length === 0 ?
          <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}>
          <ProductCard
            product={data}
            handleClose={handleClose} 
            open={open}
            deleteProduct={props.deleteProduct}
            editProduct={props.editProduct}
            downPayment={downPayment}
            category={category}
          />
          </Grid>:
          product.map(prod => (
            <Grid
              item
              key={prod.id}
              lg={4}
              md={6}
              sm={6}
              xs={12}>
              <ProductCard
                product={prod}
                handleClose={handleClose} 
                open={open}
                deleteProduct={props.deleteProduct}
                editProduct={props.editProduct}
                downPayment={downPayment}
                category={category}
                loader={props.savings}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      
      {/* Modal add investment start*/}
      <Dialog
          open={open}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle  id="alert-dialog-slide-title">Add Product</DialogTitle>  
          <Divider />     
          <DialogContent>
            <CardContent className={classes.content}>
            <ValidatorForm autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid>
                <TextValidator
                  fullWidth
                  placeholder="product image"
                  margin="dense"
                  name="image"
                  variant="outlined"
                  type="file"
                  ref={imageUploader}
                  helperText="Please select Product image"
                  onChange={handleProfileImage}
                />
                <TextValidator
                  label="category name"
                  fullWidth
                  name="category_id"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  select>
                  {category.map((option, index) => (
                    <MenuItem key={index} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>
                  ))}
                </TextValidator>
                <TextValidator
                  fullWidth
                  placeholder="Product Name"
                  margin="dense"
                  name="product_name"
                  validators={["required"]}
                  value={data.product_name}
                  variant="outlined"
                  type="text"
                  helperText="Please enter Product name"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Short Description"
                  margin="dense"
                  name="short_description"
                  validators={["required"]}
                  value={data.short_description}
                  variant="outlined"
                  type="text"
                  helperText="Please enter short description"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  placeholder="Description"
                  margin="dense"
                  name="description"
                  validators={["required"]}
                  multiline
                  row={4}
                  value={data.description}
                  variant="outlined"
                  type="text"
                  helperText="Please enter description"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Price"
                  margin="dense"
                  name="price"
                  validators={["required"]}
                  value={data.price}
                  type="number"
                  variant="outlined"
                  helperText="Please enter price"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Down Payment"
                  margin="dense"
                  name="down_payment"
                  validators={["required"]}
                  value={downPayment + "%"}
                  variant="outlined"
                  type="text"
                  helperText="Down payment"
                />
                <TextValidator
                  fullWidth
                  placeholder="Total payment"
                  margin="dense"
                  name="total_product"
                  validators={["required"]}
                  value={data.total_product}
                  type="number"
                  variant="outlined"
                  helperText="Total payment"
                />
                <TextValidator
                  fullWidth
                  placeholder="Mark Up"
                  margin="dense"
                  name="mark_up"
                  validators={["required"]}
                  value={data.mark_up}
                  type="number"
                  variant="outlined"
                  helperText="Please enter Mark Up in %"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Total Repayable Amount"
                  margin="dense"
                  name="total"
                  validators={["required"]}
                  value={data.total}
                  type="number"
                  variant="outlined"
                  helperText="Total Repayable Amount"
                />
                <TextValidator
                  fullWidth
                  placeholder="Quantity"
                  margin="dense"
                  name="quantity"
                  validators={[ "required"]}
                  value={data.quantity}
                  variant="outlined"
                  type="number"
                  helperText="Please type Quantity"
                  onChange={handleChange}
                />
              </Grid>
              {props.savings && <CircularProgress />}
              <Grid>
                <Button type="submit" color="primary" variant="contained">
                  Submit 
                </Button>
              </Grid>
            </ValidatorForm>
          </CardContent>
        </DialogContent>
      </Dialog>
      {/* Modal add investment end */}

      <div className={classes.pagination}>
        <Paginate pagination={pagination} fetch_prev_page={fetch_prev_page} fetch_next_page={fetch_next_page} fetch_page={fetch_page}/>
      </div>
    </div>
  );
};

function mapState(state) {
  const { savings } = state.savings;
  return { savings };
}
const actionCreators = {
  addProduct: adminActions.addProduct,
  logout: adminActions.logout,
  deleteProduct: adminActions.deleteProduct,
  editProduct: adminActions.editProduct
};

export default withStyles({}, { withTheme: true })(
  withRouter(connect(mapState,  actionCreators)(ProductList))
);