import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  DialogContent,
  CardActions,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Tooltip,
  Button,
  DialogTitle,
  Dialog,
  MenuItem,
  TextField
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { numberFormat } from 'redux/config/config';
import swal from 'sweetalert'

const useStyles = makeStyles(theme => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = props => {
  const { className, product, downPayment, loader, category, ...rest } = props;
  const [editProduct, setEditProduct] = useState(false)
  const [data, setData] = useState(
    {category_id:product.category_id,
    product_name:product.product_name,
    short_description:product.short_description,
    description:product.description, 
    down_payment:product.down_payment,
    mark_up:product.mark_up, 
    total_product:product.total_product,
    total:product.total,
    price:product.price, 
    quantity:product.quantity,
    image:null,
    id:product.id})

  const handleDelete = (id)=>{
    swal({
      title: "Are you sure you want to delete this product?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        props.deleteProduct(id)
        swal("Loading...",{
          buttons:false
        });
      }
    });
  }

  const handleSubmit = (event)=>{
    event.preventDefault();
    let fd = new FormData();
    fd.append('description', data.description);
    fd.append('short_description', data.short_description);
    fd.append('category_id', data.category_id);
    fd.append('product_name', data.product_name);
    fd.append('price', data.price);
    fd.append('mark_up', data.mark_up);
    fd.append('down_payment', data.down_payment);
    fd.append('total_product', data.total_product);
    fd.append('total', data.total);
    fd.append('quantity', data.quantity);
    fd.append('image', data.image);
    if(data.image !== null){
      props.editProduct({values:fd, id: data.id});
    }else{
      swal("please add image to upload")
    }
    
  }

  const handleChange = (event) =>{
    const { name, value } = event.target;
    event.persist();
    if(name === "category_id"){
      category.forEach(cat => {
        if(value === cat.category_name){
          setData(data=>({ ...data, [name]:cat.id}))
        }
      });
    }else if(name === "price"){
      const amt = (downPayment / 100) * value
      setData(data=>({ ...data, [name]:value, total_product: value - amt, down_payment:downPayment, mark_up:0, total:0}))
    }else if(name === "mark_up"){
      const amt = (Number(value) / 100) * Number(data.total_product)
      setData(data=>({ ...data, [name]:value, total:amt + Number(data.total_product)}))
    }else if(name === "image"){
      const [file] = event.target.files;
      if(file){
        setData(data=>({ ...data, image: event.target.files[0]}))
      }
    }else{
      setData(data=>({ ...data, [name]:value}))
    }
  }

  const handleEdit = (val) =>{
    setEditProduct(val)
  }
  
  const classes = useStyles();
  return (
    <div>
      {Object.entries(product).length > 1 ? 
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <div className={classes.imageContainer}>
            <img
              alt="Product"
              className={classes.image}
              src={product.image}
            />
          </div>
          <Typography
            align="center"
            gutterBottom
            variant="h4"
          >
            {product.product_name}
          </Typography>
          <Typography
            align="center"
            variant="body1"
          >
            {product.short_description.length >= 100? product.short_description.substr(0, 100):product.short_description}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            justify="space-between" >
            <Grid
              className={classes.statsItem}
              item >
              <AccessTimeIcon className={classes.statsIcon} />
              <Typography
                display="inline"
                variant="body2"
              >
                {product.quantity} Quantity 
              </Typography>
            </Grid>
            <Grid
              className={classes.statsItem}
              item >
              <Tooltip title="Delete product"><DeleteIcon className={classes.statsIcon} onClick={()=>handleDelete(product.id)} /></Tooltip>
            </Grid>
            <Grid
              className={classes.statsItem}
              item >
              <Tooltip title="Edit Product"><EditIcon className={classes.statsIcon} onClick={()=>handleEdit(true)} /></Tooltip>
            </Grid>
            <Grid item>
              <Typography
                display="inline"
                variant="body2"
                >
                {numberFormat(Number(product.price) + Number(product.mark_up))}  
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>:
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>}
      {/* Modal add investment start*/}
      <Dialog
          open={editProduct}
          fullWidth={true}
          maxWidth = {'xs'}
          keepMounted
          onClose={()=>handleEdit(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle  id="alert-dialog-slide-title">Edit Product</DialogTitle>  
          <Divider />     
          <DialogContent>
            <CardContent className={classes.content}>
            <ValidatorForm autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid>
                <TextValidator
                  fullWidth
                  placeholder="product image"
                  margin="dense"
                  name="image"
                  variant="outlined"
                  type="file"
                  helperText="Please select Product image"
                  onChange={handleChange}
                />
                <TextValidator
                  label="category name"
                  fullWidth
                  name="category_id"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  select>
                  {category.map((option, index) => (
                    <MenuItem key={index} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>
                  ))}
                </TextValidator>
                <TextValidator
                  fullWidth
                  placeholder="Product Name"
                  margin="dense"
                  name="product_name"
                  validators={["required"]}
                  value={data.product_name}
                  variant="outlined"
                  type="text"
                  helperText="Please enter Product name"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Short Description"
                  margin="dense"
                  name="short_description"
                  validators={["required"]}
                  value={data.short_description}
                  variant="outlined"
                  type="text"
                  helperText="Please enter short description"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  placeholder="Description"
                  margin="dense"
                  name="description"
                  validators={["required"]}
                  multiline
                  row={4}
                  value={data.description}
                  variant="outlined"
                  type="text"
                  helperText="Please enter description"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Price"
                  margin="dense"
                  name="price"
                  validators={["required"]}
                  value={data.price}
                  type="number"
                  variant="outlined"
                  helperText="Please enter price"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Down Payment"
                  margin="dense"
                  name="down_payment"
                  validators={["required"]}
                  value={downPayment + "%"}
                  variant="outlined"
                  type="text"
                  helperText="Down payment"
                />
                <TextValidator
                  fullWidth
                  placeholder="Total payment"
                  margin="dense"
                  name="total_product"
                  validators={["required"]}
                  value={data.total_product}
                  type="number"
                  variant="outlined"
                  helperText="Total payment"
                />
                <TextValidator
                  fullWidth
                  placeholder="Mark Up"
                  margin="dense"
                  name="mark_up"
                  validators={["required"]}
                  value={data.mark_up}
                  type="number"
                  variant="outlined"
                  helperText="Please enter Mark Up in %"
                  onChange={handleChange}
                />
                <TextValidator
                  fullWidth
                  placeholder="Total Repayable Amount"
                  margin="dense"
                  name="total"
                  validators={["required"]}
                  value={data.total}
                  type="number"
                  variant="outlined"
                  helperText="Total Repayable Amount"
                />
                <TextValidator
                  fullWidth
                  placeholder="Quantity"
                  margin="dense"
                  name="quantity"
                  validators={[ "required"]}
                  value={data.quantity}
                  variant="outlined"
                  type="number"
                  helperText="Please type Quantity"
                  onChange={handleChange}
                />
              </Grid>
              {loader && <CircularProgress />}
              <Grid>
                <Button type="submit" color="primary" variant="contained">
                  Submit 
                </Button>
              </Grid>
            </ValidatorForm>
          </CardContent>
        </DialogContent>
      </Dialog>
      {/* Modal add investment end */}
    </div>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
